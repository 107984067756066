import React, { useState } from 'react';
import { History, RotateCw } from 'lucide-react';

const OriginStorySection = () => {
  const [flippedStates, setFlippedStates] = useState([false, false]);

  const toggleFlip = (index) => {
    setFlippedStates(prev => prev.map((state, i) => i === index ? !state : state));
  };

  const stories = [
    {
      title: "The Catalyst",
      content: "Following the Marshall Fire in December 2021, our founder Jackson White witnessed firsthand how families struggled with claim verification and the devastating loss of irreplaceable treasures."
    },
    {
      title: "The Mission",
      content: "A personal family loss in 2023 crystallized the vision for Treasure Tracker: creating a tool that not only expedites the claim process but preserves the precious memories our treasured possessions hold."
    }
  ];

  return (
    <section className="py-16 bg-gradient-to-b from-gray-50 to-white relative overflow-hidden" aria-labelledby="origin-title">
      {/* Decorative background elements */}
      <div className="absolute inset-0 opacity-5">
        <div className="absolute top-0 left-1/4 w-64 h-64 bg-blue-500 rounded-full mix-blend-multiply filter blur-3xl animate-pulse" />
        <div className="absolute bottom-0 right-1/4 w-64 h-64 bg-purple-500 rounded-full mix-blend-multiply filter blur-3xl animate-pulse delay-1000" />
      </div>

      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <div className="flex items-center justify-center gap-2 mb-8">
            <div className="h-px w-8 bg-gradient-to-r from-blue-500 to-transparent" />
            <History className="w-6 h-6 text-blue-600" />
            <div className="h-px w-8 bg-gradient-to-l from-blue-500 to-transparent" />
          </div>

          <h2
            id="origin-title"
            className="text-4xl font-bold mb-12 text-center
                     bg-gradient-to-r from-blue-700 via-purple-600 to-blue-700 
                     bg-clip-text text-transparent"
          >
            Where It All Began
          </h2>

          <div className="grid md:grid-cols-2 gap-8">
            {stories.map((story, index) => (
              <div key={index} className="perspective-1000 w-full h-[280px]">
                <div 
                  className={`relative w-full h-full transition-transform duration-500 transform-style-preserve-3d cursor-pointer 
                           ${flippedStates[index] ? 'rotate-y-180' : ''}`}
                  onClick={() => toggleFlip(index)}
                  role="button"
                  aria-label={flippedStates[index] ? "Click to see title" : "Click to see story"}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      toggleFlip(index);
                    }
                  }}
                >
                  {/* Front */}
                  <div className="absolute w-full h-full backface-hidden bg-white rounded-xl shadow-lg overflow-hidden">
                    <div className="absolute top-0 right-0 w-32 h-32 bg-blue-500/5 rounded-bl-full" />
                    <div className="h-full flex flex-col items-center justify-center p-8">
                      <h3 className="text-3xl font-bold bg-gradient-to-r from-blue-600 via-purple-600 to-blue-600 bg-clip-text text-transparent
                                 [text-shadow:_0_2px_20px_rgba(66,153,225,0.2)] tracking-tight mb-4">
                        {story.title}
                      </h3>
                      <div className="flex items-center gap-2 text-blue-600/70 text-sm">
                        <RotateCw className="w-4 h-4 animate-pulse" />
                        <span>Click to flip</span>
                      </div>
                    </div>
                  </div>
                  
                  {/* Back */}
                  <div className="absolute w-full h-full backface-hidden rotate-y-180 bg-gradient-to-br from-blue-50 to-purple-50 rounded-xl shadow-lg overflow-hidden">
                    <div className="h-full flex flex-col justify-between p-8">
                      <p className="text-gray-600 leading-relaxed">
                        {story.content}
                      </p>
                      <div className="flex items-center justify-center gap-2 text-blue-600/70 text-sm mt-4">
                        <RotateCw className="w-4 h-4 animate-pulse" />
                        <span>Click to flip back</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-12 text-center">
            <p className="text-lg text-gray-600 max-w-2xl mx-auto leading-relaxed">
              Today, Treasure Tracker stands as a bridge between generations, helping families protect and
              preserve their most precious memories while simplifying the practical aspects of asset management.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OriginStorySection;