import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FolderOpen, BookHeart, Gift, ChevronDown } from 'lucide-react';
import PressLogoBanner from '../components/PressLogoBanner';
import TestimonialsSection from '../components/TestimonialsSection';
import SEOHead from '../components/SEOHead';

const HomePage = () => {
  const [deviceType, setDeviceType] = useState('desktop');
  const [selectedVideo, setSelectedVideo] = useState('');

  const videos = [
    'PG9eMwfl_Ho',
    'HRxCANJ9ILk',
    '2237YR6WwFs',
    'kGCDH4NGPfI'
  ];

  const getMobileOperatingSystem = () => {
    // For testing purposes
    const isDevMode = process.env.NODE_ENV === 'development';
    if (isDevMode) {
      const urlParams = new URLSearchParams(window.location.search);
      const deviceParam = urlParams.get('device');
      if (deviceParam) {
        return deviceParam;
      }
    }

    const userAgent = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(userAgent)) {
      return 'ios';
    } else if (/android/.test(userAgent)) {
      return 'android';
    }
    return 'desktop';
  };

  useEffect(() => {
    setDeviceType(getMobileOperatingSystem());
    const randomVideo = videos[Math.floor(Math.random() * videos.length)];
    setSelectedVideo(randomVideo);
  }, []);

  const FeatureCard = ({ icon: Icon, title, description, imageSrc }) => (
    <div
      className="group flex flex-col items-center h-[800px] p-8 rounded-2xl bg-gray-900 shadow-sm 
                 hover:shadow-xl transition-all duration-300 relative overflow-hidden"
      role="article"
      aria-labelledby={`feature-title-${title.toLowerCase().replace(/\s+/g, '-')}`}
    >
      <div
        className="absolute inset-0 bg-gradient-to-br from-gray-900/50 to-gray-800/50 
                   opacity-0 group-hover:opacity-100 transition-opacity duration-300"
        aria-hidden="true"
      />

      <div className="relative z-10 flex flex-col items-center w-full h-full">
        <div className="flex flex-col items-center h-[120px] justify-center">
          <div
            className="p-4 rounded-full bg-gradient-to-br from-blue-500/10 to-purple-500/10 
                     group-hover:scale-110 transition-transform duration-300 mb-4"
            aria-hidden="true"
          >
            <Icon className="w-8 h-8 text-blue-400" />
          </div>

          <h3
            id={`feature-title-${title.toLowerCase().replace(/\s+/g, '-')}`}
            className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 
                     bg-clip-text text-transparent text-center"
          >
            {title}
          </h3>
        </div>

        {/* Description div stays exactly the same */}
        <div className="h-[100px] flex items-center">
          <p className="text-gray-400 text-center text-lg leading-relaxed">
            {description}
          </p>
        </div>

        {/* Image container stays exactly the same */}
        <div className="w-full h-[520px] flex items-center justify-center bg-gray-800 rounded-xl p-4">
          <img
            src={imageSrc}
            alt={`${title} feature demonstration`}
            className="h-[480px] w-[240px] object-contain rounded-lg shadow-lg mx-auto
                    transform group-hover:scale-105 transition-transform duration-300"
            onError={(e) => {
              e.target.src = `/api/placeholder/240/480`;
            }}
          />
        </div>
      </div>
    </div>
  );

  const features = [
    {
      icon: FolderOpen,
      title: "Organize Treasures",
      description: "Quickly and easily sort your most cherished possessions by location or category.",
      imageSrc: "/images/organize.png"
    },
    {
      icon: BookHeart,
      title: "Share Stories",
      description: "Connect with family and preserve the rich history behind each treasure.",
      imageSrc: "/images/story.png"
    },
    {
      icon: Gift,
      title: "Assign Assets",
      description: "Make sure your treasures go where you want them to go.",
      imageSrc: "/images/assign.png"
    }
  ];

  return (
    <div className="flex flex-col min-h-screen relative overflow-hidden">
      <SEOHead
        title="Treasure Tracker - Preserve Your Family's Legacy"
        description="Document and share your family's treasured possessions with Treasure Tracker. Our easy-to-use app helps you preserve memories and connect generations."
        path="/"
      />
      <main id="main-content">
        <div className="relative">
          {/* Hero Section */}
          <section className="relative min-h-screen pt-20 md:pt-32 pb-16" aria-labelledby="hero-title">
            {/* Background gradient */}
            <div
              className="absolute inset-0 bg-gradient-to-br from-white/90 to-blue-500/90 backdrop-blur-sm"
              aria-hidden="true"
            ></div>

            {/* Dot pattern overlay */}
            <div
              className="absolute inset-0 opacity-10"
              style={{
                backgroundImage: `radial-gradient(circle at 1px 1px, rgba(255, 255, 255, 0.15) 1px, transparent 0)`,
                backgroundSize: '20px 20px'
              }}
              aria-hidden="true"
            >
            </div>

            {/* Content */}
            {/* Modified content section with fixed grid layout */}
            <div className="relative container mx-auto px-4 pt-12 md:pt-0">
              <div className="grid md:grid-cols-2 gap-8 md:gap-12 items-start">
                {/* Left Column - Content */}
                <div className="flex flex-col space-y-6 md:space-y-8">
                  {/* Title */}
                  <h1
                    id="hero-title"
                    className="text-4xl md:text-5xl lg:text-6xl font-gilmer font-bold
                             bg-gradient-to-r from-blue-700 via-blue-500 to-purple-700
                             bg-clip-text text-transparent
                             [text-shadow:_0_0_30px_rgba(255,255,255,0.3)]"
                  >
                    Bring Your Family's Treasures to Life
                  </h1>

                  {/* Text Content */}
                  <div className="space-y-3 md:space-y-4">
                    <p className="text-base md:text-lg text-blue-700">
                      Every family treasure tells a story.
                    </p>
                    <p className="text-base md:text-lg text-blue-700">
                      From cherished heirlooms to everyday artifacts, each item holds memories
                      waiting to be preserved and shared.
                    </p>
                    <p className="text-base md:text-lg text-blue-700">
                      Our app helps you capture these precious stories, protect their legacy,
                      and connect generations through the power of shared history.
                    </p>
                    {/* CTA text */}
                    <p className="text-base md:text-lg font-medium relative inline-block">
                      <span className="relative z-10 text-violet-600">
                        Start documenting your family's unique journey today.
                      </span>
                      <span className="absolute inset-0 blur-md text-white opacity-100 animate-pulse">
                        Start documenting your family's unique journey today.
                      </span>
                      <span className="absolute inset-0 blur-xl text-white opacity-100">
                        Start documenting your family's unique journey today.
                      </span>
                      <span className="absolute inset-0 blur-2xl text-white opacity-100">
                        Start documenting your family's unique journey today.
                      </span>
                    </p>
                  </div>
                  {/*bg-gradient-to-r from-purple-700 via-purple-500 to-purple-700 bg-clip-text text-transparent*/}
                  {/* Dynamic Buttons */}
                  <div className="pt-4 md:pt-6">
                    <nav aria-label="Primary call to action">
                      {deviceType === 'desktop' ? (
                        <div className="flex flex-col md:flex-row items-center gap-4 md:gap-6">
                          <Link
                            to="/download"
                            className="w-full md:w-auto bg-white text-blue-500 px-8 py-3 rounded-full font-medium
                                     text-center transition-all duration-200 ease-out
                                     hover:bg-opacity-90 hover:shadow-[0_0_20px_rgba(255,255,255,0.3)]
                                     hover:scale-105"
                          >
                            Try Free Today
                          </Link>
                          <Link
                            to="/product"
                            className="w-full md:w-auto text-white px-8 py-3 rounded-full font-medium
                                     text-center transition-colors duration-200 ease-out
                                     hover:bg-white/10"
                          >
                            Learn More
                          </Link>
                        </div>
                      ) : deviceType === 'ios' ? (
                        <div className="flex flex-col md:flex-row items-center gap-4 md:gap-6">
                          <a
                            href="https://apps.apple.com/us/app/treasure-tracker-app/id6470204759"
                            className="w-full md:w-auto bg-white text-blue-500 px-8 py-3 rounded-full font-medium
                                     text-center transition-all duration-200 ease-out
                                     hover:bg-opacity-90 hover:shadow-[0_0_20px_rgba(255,255,255,0.3)]
                                     hover:scale-105"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download for iOS
                          </a>
                          <Link
                            to="/product"
                            className="w-full md:w-auto text-white px-8 py-3 rounded-full font-medium
                                     text-center transition-colors duration-200 ease-out
                                     hover:bg-white/10"
                          >
                            Learn More
                          </Link>
                        </div>
                      ) : (
                        <div className="flex flex-col md:flex-row items-center gap-4 md:gap-6">
                          <a
                            href="https://play.google.com/store/apps/details?id=com.treasure.tracker.production&hl=en"
                            className="w-full md:w-auto bg-white text-blue-500 px-8 py-3 rounded-full font-medium
                                     text-center transition-all duration-200 ease-out
                                     hover:bg-opacity-90 hover:shadow-[0_0_20px_rgba(255,255,255,0.3)]
                                     hover:scale-105"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download for Android
                          </a>
                          <Link
                            to="/product"
                            className="w-full md:w-auto text-white px-8 py-3 rounded-full font-medium
                                     text-center transition-colors duration-200 ease-out
                                     hover:bg-white/10"
                          >
                            Learn More
                          </Link>
                        </div>
                      )}
                    </nav>
                  </div>
                </div>

                {/* Right Column - Video */}
                <div className="relative w-full md:mt-16">
                  {/* Glow effects */}
                  <div
                    className="absolute -inset-4 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-xl blur-xl"
                    aria-hidden="true"
                  ></div>
                  <div
                    className="absolute -inset-3 bg-gradient-to-br from-blue-400/20 via-transparent to-purple-400/20 rounded-xl blur-lg"
                    aria-hidden="true"
                  ></div>

                  {/* Video container with enhanced styling */}
                  <div className="relative rounded-lg overflow-hidden shadow-lg 
                                ring-1 ring-white/20 backdrop-blur-sm
                                transition-all duration-300 hover:ring-white/30 hover:shadow-2xl">
                    {selectedVideo && (
                      <div className="relative pt-[56.25%]">
                        <iframe
                          className="absolute inset-0 w-full h-full rounded-lg"
                          src={`https://www.youtube.com/embed/${selectedVideo}?autoplay=1&mute=1&loop=1&playlist=${selectedVideo}`}
                          title="Treasure Tracker Demo"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <PressLogoBanner />

          {/* Features Section */}
          <section
            className="py-24 bg-gradient-to-b from-gray-50 to-white"
            aria-labelledby="features-title"
          >
            <div className="container mx-auto px-4">
              {/* Section Header - Added padding bottom to container */}
              <div className="max-w-3xl mx-auto text-center mb-20">
                <h2
                  id="features-title"
                  className="text-4xl md:text-5xl font-bold mb-6 leading-tight
                           bg-gradient-to-r from-blue-700 via-purple-600 to-blue-700 
                           bg-clip-text text-transparent pb-1"
                >
                  Everything You Need
                </h2>
                <p className="text-xl text-gray-600">
                  Preserve your family's legacy with our powerful yet simple tools
                </p>
              </div>

              {/* Feature Cards */}
              <div
                className="grid md:grid-cols-3 gap-8"
                role="region"
                aria-label="Product features"
              >
                {features.map((feature, index) => (
                  <FeatureCard key={index} {...feature} />
                ))}
              </div>

              {/* CTA Button - Updated with visible background color */}
              <div className="mt-16 text-center">
                <div className="inline-flex flex-col items-center">
                  {deviceType === 'desktop' ? (
                    <Link
                      to="/download"
                      className="bg-blue-600 text-white px-8 py-3 rounded-full font-medium
                               transition-all duration-200 ease-out
                               hover:bg-blue-500 hover:shadow-[0_0_20px_rgba(59,130,246,0.5)]
                               hover:scale-105"
                    >
                      Start Free Trial
                    </Link>
                  ) : (
                    <a
                      href={deviceType === 'ios'
                        ? "https://apps.apple.com/us/app/treasure-tracker-app/id6470204759"
                        : "https://play.google.com/store/apps/details?id=com.treasure.tracker.production&hl=en"
                      }
                      className="bg-blue-600 text-white px-8 py-3 rounded-full font-medium
                               transition-all duration-200 ease-out
                               hover:bg-blue-500 hover:shadow-[0_0_20px_rgba(59,130,246,0.5)]
                               hover:scale-105"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download for {deviceType === 'ios' ? 'iOS' : 'Android'}
                    </a>
                  )}
                  <p className="mt-4 text-gray-500">No credit card required</p>
                </div>
              </div>
            </div>
          </section>

          {/* Social Proof Section */}
          <TestimonialsSection />

          {/* CTA Section */}
          <section
            className="py-20 bg-gradient-to-r from-primary-purple to-primary-sky"
            aria-labelledby="cta-title"
          >
            <div className="container mx-auto px-4 text-center">
              <h2
                id="cta-title"
                className="text-3xl md:text-4xl font-gilmer font-bold text-white mb-6"
              >
                Start Preserving Your Legacy Today
              </h2>
              <p className="text-xl text-white/80 mb-8 max-w-2xl mx-auto">
                Join hundreds of families who are documenting their treasures and sharing their stories with future generations.
              </p>
              {deviceType === 'desktop' ? (
                <Link
                  to="/download"
                  className="bg-white text-primary-purple px-8 py-3 rounded-full font-medium
                           transition-all duration-200 ease-out
                           hover:bg-opacity-90 hover:shadow-[0_0_20px_rgba(255,255,255,0.3)]
                           hover:scale-105"
                >
                  Try Free Today
                </Link>
              ) : deviceType === 'ios' ? (
                <a
                  href="https://apps.apple.com/us/app/treasure-tracker-app/id6470204759"
                  className="bg-white text-primary-purple px-8 py-3 rounded-full font-medium
                           transition-all duration-200 ease-out
                           hover:bg-opacity-90 hover:shadow-[0_0_20px_rgba(255,255,255,0.3)]
                           hover:scale-105"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download for iOS
                </a>
              ) : (
                <a
                  href="https://play.google.com/store/apps/details?id=com.treasure.tracker.production&hl=en"
                  className="bg-white text-primary-purple px-8 py-3 rounded-full font-medium
                           transition-all duration-200 ease-out
                           hover:bg-opacity-90 hover:shadow-[0_0_20px_rgba(255,255,255,0.3)]
                           hover:scale-105"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download for Android
                </a>
              )}
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default HomePage;