import React from 'react';
import { Card, CardContent } from './ui/Card';
import { AiOutlineLinkedin, AiOutlineLink } from 'react-icons/ai';

const TeamMember = ({ name, role, bio, imageSrc, socials }) => (
    <Card className="group hover:shadow-xl transition-all duration-300 
                   bg-gradient-to-br from-white via-white to-blue-50">
      <CardContent className="p-6 text-center">
        <div className="mb-6 relative">
          <div className="w-32 h-32 mx-auto rounded-full overflow-hidden 
                      bg-gradient-to-br from-blue-500/10 to-purple-500/10
                      group-hover:from-blue-500/20 group-hover:to-purple-500/20 
                      transition-all duration-300">
            <img
              src={imageSrc}
              alt={name}
              className="w-full h-full object-cover"
              onError={(e) => {
                e.target.src = `/api/placeholder/128/128`;
              }}
            />
          </div>
        </div>
        <h3 className="text-xl font-bold text-blue-700 mb-2">{name}</h3>
        <p className="text-sm text-purple-600 mb-4">{role}</p>
        <p className="text-gray-600 text-sm mb-4">{bio}</p>
        
        {/* Social Links */}
        {(socials?.linkedin || socials?.website) && (
          <div className="flex items-center justify-center gap-4 pt-3">
            {socials.linkedin && (
              <a 
                href={socials.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 text-blue-600 hover:text-white bg-transparent hover:bg-blue-600 
                         rounded-full transition-all duration-300 ease-in-out
                         hover:shadow-lg hover:scale-110 group/icon"
                aria-label={`Visit ${name}'s LinkedIn profile`}
              >
                <AiOutlineLinkedin className="w-6 h-6" />
                <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 
                              bg-blue-600 text-white text-xs py-1 px-2 rounded 
                              opacity-0 group-hover/icon:opacity-100 transition-opacity duration-300
                              whitespace-nowrap">
                  LinkedIn Profile
                </span>
              </a>
            )}
            {socials.website && (
              <a 
                href={socials.website}
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 text-purple-600 hover:text-white bg-transparent hover:bg-purple-600 
                         rounded-full transition-all duration-300 ease-in-out
                         hover:shadow-lg hover:scale-110 group/icon"
                aria-label={`Visit ${name}'s website`}
              >
                <AiOutlineLink className="w-6 h-6" />
                <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 
                              bg-purple-600 text-white text-xs py-1 px-2 rounded 
                              opacity-0 group-hover/icon:opacity-100 transition-opacity duration-300
                              whitespace-nowrap">
                  Visit Website
                </span>
              </a>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );

const teamMembers = [
  {
    name: "Jackson White",
    role: "Founder & CEO",
    bio: "Passionate about connecting families and preserving memories.",
    imageSrc: "/images/jackson-white.png",
    socials: {
      linkedin: "https://www.linkedin.com/in/jackson-white-founder/",
      website: "/"
    }
  },
  {
    name: "David White",
    role: "Board of Advisors",
    bio: "30+ years of business management experience and avid art collector.",
    imageSrc: "/images/david-white.png",
    socials: {
      linkedin: "https://www.linkedin.com/in/david-white-7605801b/",
      website: "https://askphc.com/"
    }
  },
  {
    name: "Jim Smith",
    role: "Board of Advisors",
    bio: "Insurance industry expert with over 30 years of experience.",
    imageSrc: "/images/jim-smith.png",
    socials: {} // Empty object for no social links
  },
  {
    name: "Mark Lusky",
    role: "Board of Advisors",
    bio: "Marketing copywriter with expertise in storytelling.",
    imageSrc: "/images/mark-lusky.png",
    socials: {
      linkedin: "https://www.linkedin.com/in/marklusky/",
      website: "https://www.markluskycommunications.com/"
    }
  }
];

const TeamSection = () => {
  return (
    <section className="py-16 bg-gradient-to-b from-gray-50/50 to-white relative" aria-labelledby="team-title">
      {/* Subtle background pattern */}
      <div className="absolute inset-0 opacity-[0.015]"
        style={{
          backgroundImage: `radial-gradient(circle at 1px 1px, rgb(59 130 246) 1px, transparent 0)`,
          backgroundSize: '40px 40px'
        }}
      />
      
      <div className="container mx-auto px-4 relative">
        <h2
          id="team-title"
          className="text-4xl font-bold text-center mb-16
                   bg-gradient-to-r from-blue-700 via-purple-600 to-blue-700 
                   bg-clip-text text-transparent"
        >
          Meet Our Team
        </h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {teamMembers.map((member, index) => (
            <TeamMember key={index} {...member} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamSection;