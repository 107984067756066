import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Send, Mail, CheckCircle2, Loader2 } from 'lucide-react';
import {
  FaXTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
  FaTiktok,
  FaPhone,
} from 'react-icons/fa6';

const Footer = () => {
  const location = useLocation();
  const [formData, setFormData] = useState({ name: '', email: '' });
  const [submitStatus, setSubmitStatus] = useState('idle');
  const [isShaking, setIsShaking] = useState(false);
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch blog posts
  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/blog-posts`);
        const data = await response.json();

        if (data.data) {
          // Format and sort posts by date
          const formattedPosts = data.data
            .map(post => ({
              title: post.Title,
              description: post.Description,
              slug: post.Slug,
              date: new Date(post.PublishedDate || post.CreatedAt),
            }))
            .sort((a, b) => b.date - a.date) // Sort by newest first
            .slice(0, 2); // Get only the latest 2 posts

          setBlogPosts(formattedPosts);
        }
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPosts();
  }, []);

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    }).format(date);
  };

  const handleNewsletterSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus('loading');

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/newsletter-signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.text();
        console.error('Server Error:', errorData);
        throw new Error(response.status === 404 ? 'API endpoint not found' : 'Failed to subscribe to newsletter');
      }

      const data = await response.json();
      setSubmitStatus('success');

      // Reset form after success
      setTimeout(() => {
        setFormData({ name: '', email: '' });
        setSubmitStatus('idle');
      }, 3000);

    } catch (error) {
      console.error('Newsletter Error:', error);
      setSubmitStatus('error');
      setIsShaking(true);
      setTimeout(() => {
        setIsShaking(false);
        setSubmitStatus('idle');
      }, 2000);
    }
  };

  return (
    <footer className="bg-primary-navy text-white" role="contentinfo" aria-label="Site footer">
      <div className="container mx-auto px-6 py-16">
        {/* Top Section */}
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-12">
          {/* Logo Section */}
          <div className="lg:col-span-3">
            <Link
              to="/"
              className="inline-block mb-6 transform hover:scale-105 transition-transform duration-300"
              aria-label="Go to Treasure Tracker homepage"
            >
              <img
                src="/images/header.png"
                alt="Treasure Tracker Logo"
                className="h-10 w-auto"
              />
            </Link>
            <p className="text-lg text-primary-pink/90 font-gilmer" role="heading" aria-level="2">
              Connecting generations by bringing treasures to life
            </p>
          </div>

          {/* Newsletter Section */}
          <div className="lg:col-span-4">
            <div className="space-y-4">
              <h2 className="text-xl font-gilmer font-medium">Stay Connected with Family Stories</h2>
              <p className="text-primary-pink/80">Join our newsletter to receive tips on preserving your family's legacy.</p>
              <form
                onSubmit={handleNewsletterSubmit}
                className={`space-y-3 ${isShaking ? 'animate-shake' : ''}`}
                aria-label="Newsletter signup form"
                noValidate
              >
                <div className="space-y-3">
                  <label htmlFor="name" className="sr-only">Your name</label>
                  <input
                    id="name"
                    type="text"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    placeholder="Your name"
                    className="w-full px-4 py-3 rounded-lg bg-white/5 border border-primary-pink/20 
                             text-white placeholder-primary-pink/60 focus:outline-none focus:border-primary-pink
                             focus:bg-white/10 transition-all"
                    required
                    disabled={submitStatus === 'success'}
                    aria-required="true"
                    aria-invalid={false}
                  />

                  <label htmlFor="email" className="sr-only">Your email address</label>
                  <input
                    id="email"
                    type="email"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    placeholder="Your email"
                    className="w-full px-4 py-3 rounded-lg bg-white/5 border border-primary-pink/20 
                             text-white placeholder-primary-pink/60 focus:outline-none focus:border-primary-pink
                             focus:bg-white/10 transition-all"
                    required
                    disabled={submitStatus === 'success'}
                    aria-required="true"
                    aria-invalid={false}
                  />
                </div>

                <div className="relative" role="status" aria-live="polite">
                  <button
                    type="submit"
                    disabled={submitStatus !== 'idle'}
                    aria-disabled={submitStatus !== 'idle'}
                    className={`w-full px-6 py-3 rounded-lg transition-all duration-300 
                flex items-center justify-center gap-2 group
                ${submitStatus === 'loading' ? 'bg-primary-pink opacity-75' :
                        submitStatus === 'idle' ?
                          'bg-primary-pink hover:bg-primary-pink/90 hover:shadow-lg hover:shadow-primary-pink/20 transform hover:-translate-y-0.5' :
                          submitStatus === 'error' ?
                            'bg-red-500' :
                            'bg-primary-pink opacity-0'}`}
                  >
                    {submitStatus === 'loading' ? (
                      <>
                        <span>Subscribing...</span>
                        <Loader2 className="h-4 w-4 animate-spin" />
                      </>
                    ) : (
                      <>
                        <span className="group-hover:scale-105 transition-transform">
                          {submitStatus === 'error' ? 'Try Again' : 'Subscribe to Newsletter'}
                        </span>
                        <Send className={`h-4 w-4 group-hover:translate-x-1 transition-all duration-300
                            ${submitStatus === 'error' ? 'opacity-0' : 'opacity-100'}`}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </button>

                  {/* Success Message */}
                  <div
                    className={`absolute inset-0 flex items-center justify-center gap-2
                               transition-opacity duration-300 ${submitStatus === 'success' ? 'opacity-100' : 'opacity-0 pointer-events-none'
                      }`}
                    role="alert"
                  >
                    <span className="text-green-400">Thank you for subscribing!</span>
                    <CheckCircle2
                      className="h-5 w-5 text-green-400 animate-success"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/* Blog Section */}
          <div className="lg:col-span-5 space-y-4">
            <h2 className="text-xl font-gilmer font-medium">Latest from Our Blog</h2>
            <div className="grid grid-cols-1 gap-4" role="list">
              {loading ? (
                // Show original hardcoded entries while loading
                <>
                  <article role="listitem">
                    <Link to="/blog/preserving-heirlooms" className="block bg-white/5 rounded-lg p-4 hover:bg-white/10 transition-colors group">
                      <time className="text-sm text-primary-pink/70" dateTime="2024-03-15">Mar 15, 2024</time>
                      <h3 className="font-gilmer text-white mt-1 group-hover:text-primary-pink transition-colors">Preserving Family Heirlooms</h3>
                      <p className="text-primary-pink/80 text-sm mt-2">Learn the best practices for maintaining your precious family items.</p>
                    </Link>
                  </article>
                  <article role="listitem">
                    <Link to="/blog/digital-legacy" className="block bg-white/5 rounded-lg p-4 hover:bg-white/10 transition-colors group">
                      <time className="text-sm text-primary-pink/70" dateTime="2024-03-10">Mar 10, 2024</time>
                      <h3 className="font-gilmer text-white mt-1 group-hover:text-primary-pink transition-colors">Digital Legacy Planning</h3>
                      <p className="text-primary-pink/80 text-sm mt-2">Essential steps for protecting your digital memories.</p>
                    </Link>
                  </article>
                </>
              ) : (
                // Show real blog posts when loaded
                blogPosts.map((post) => (
                  <article key={post.slug} role="listitem">
                    <Link to={`/blog/${post.slug}`} className="block bg-white/5 rounded-lg p-4 hover:bg-white/10 transition-colors group">
                      <time className="text-sm text-primary-pink/70" dateTime={post.date.toISOString()}>
                        {formatDate(post.date)}
                      </time>
                      <h3 className="font-gilmer text-white mt-1 group-hover:text-primary-pink transition-colors">
                        {post.title}
                      </h3>
                      <p className="text-primary-pink/80 text-sm mt-2">
                        {post.description}
                      </p>
                    </Link>
                  </article>
                ))
              )}
            </div>
          </div>
        </div>

        {/* Navigation Section */}
        <nav className="grid grid-cols-1 md:grid-cols-4 gap-8 mt-16 pt-8 border-t border-primary-pink/20" aria-label="Footer navigation">
          {/* Company Links */}
          <div className="text-center md:text-left">
            <h2 className="font-gilmer font-medium text-lg mb-4">Company</h2>
            <ul className="space-y-3" role="list">
              {['/about', '/blog', '/contact'].map((path) => (
                <li key={path}>
                  <Link
                    to={path}
                    className={`block transition-all relative ${location.pathname === path
                      ? 'text-white font-medium border-b border-primary-pink'
                      : 'text-primary-pink hover:text-white hover:translate-x-1'
                      }`}
                    aria-current={location.pathname === path ? 'page' : undefined}
                  >
                    <span className={location.pathname === path ? 'text-shadow-glow' : ''}>
                      {path.slice(1).charAt(0).toUpperCase() + path.slice(2)}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>


          {/* Product Links */}
          <div className="text-center md:text-left">
            <h2 className="font-gilmer font-medium text-lg mb-4">Product</h2>
            <ul className="space-y-3" role="list">
              {[
                { path: '/product', label: 'Features' },
                { path: '/pricing', label: 'Pricing' },
                { path: '/partnerships', label: 'Partners' }
              ].map(({ path, label }) => (
                <li key={path}>
                  <Link
                    to={path}
                    className={`block transition-all relative ${location.pathname === path
                      ? 'text-white font-medium border-b border-primary-pink'
                      : 'text-primary-pink hover:text-white hover:translate-x-1'
                      }`}
                    aria-current={location.pathname === path ? 'page' : undefined}
                  >
                    <span className={location.pathname === path ? 'text-shadow-glow' : ''}>
                      {label}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Legal Links */}
          <div className="text-center md:text-left">
            <h2 className="font-gilmer font-medium text-lg mb-4">Legal</h2>
            <ul className="space-y-3" role="list">
              {[
                { path: '/privacy', label: 'Privacy Policy' },
                { path: '/terms', label: 'Terms & Conditions' },
                { path: '/data-deletion', label: 'Data Deletion' }
              ].map(({ path, label }) => (
                <li key={path}>
                  <Link
                    to={path}
                    className={`block transition-all relative ${location.pathname === path
                      ? 'text-white font-medium border-b border-primary-pink'
                      : 'text-primary-pink hover:text-white hover:translate-x-1'
                      }`}
                    aria-current={location.pathname === path ? 'page' : undefined}
                  >
                    <span className={location.pathname === path ? 'text-shadow-glow' : ''}>
                      {label}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Info */}
          <div className="text-center md:text-left">
            <h2 className="font-gilmer font-medium text-lg mb-4">Contact</h2>
            <address className="space-y-3 not-italic">
              <p className="flex items-center text-primary-pink justify-center md:justify-start">
                <a
                  href="tel:303-241-7833"
                  className="flex items-center text-primary-pink hover:text-white transition-colors"
                  aria-label="Call us at 303-241-7833"
                >
                  <FaPhone size={16} className="mr-2" aria-hidden="true" />
                  303-241-7833
                </a>
              </p>
              <p className="flex items-center text-primary-pink justify-center md:justify-start">
                <a
                  href="mailto:jackson.white@mytreasuretracker.com"
                  className="flex items-center text-primary-pink hover:text-white transition-colors"
                  aria-label="Email us at jackson.white@mytreasuretracker.com"
                >
                  <Mail className="h-4 w-4 mr-2" aria-hidden="true" />
                  jackson.white@mytreasuretracker.com
                </a>
              </p>

              {/* Social Links */}
              <div className="flex items-center gap-4 mt-4 justify-center md:justify-start" role="list" aria-label="Social media links">
                {[
                  { Icon: FaXTwitter, href: "https://x.com/TreasureTrakLLC", label: "Follow us on X" },
                  { Icon: FaInstagram, href: "https://www.instagram.com/the_treasure_tracker/", label: "Follow us on Instagram" },
                  { Icon: FaFacebookF, href: "https://www.facebook.com/profile.php?id=61554894507503r", label: "Visit our Facebook page" },
                  { Icon: FaTiktok, href: "https://www.tiktok.com/@treasuretrackerllc", label: "Follow us on TikTok" },
                  { Icon: FaLinkedinIn, href: "https://www.linkedin.com/company/treasure-tracker/", label: "Connect with us on LinkedIn" }
                ].map(({ Icon, href, label }) => (
                  <a
                    key={href}
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary-pink hover:text-white transition-all transform hover:scale-110"
                    aria-label={label}
                  >
                    <Icon size={18} aria-hidden="true" />
                  </a>
                ))}
              </div>
            </address>
          </div>
        </nav>

        {/* Copyright */}
        <div className="mt-16 pt-8 border-t border-primary-pink/20 text-center">
          <p className="text-primary-pink">
            <small>&copy; {new Date().getFullYear()} Treasure Tracker LLC. All rights reserved.</small>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;