import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Shield, Camera, Share, Send, Sparkles, ChevronRight, Loader2, Check, XCircle } from 'lucide-react';
import FeatureCarousel from '../components/FeatureCarousel';
import HowItWorksSection from '../components/HowItWorks';
import SecuritySection from '../components/SecuritySection';
import SEOHead from '../components/SEOHead';

const ProductPage = () => {
  const [deviceType, setDeviceType] = useState('desktop');
  const [formData, setFormData] = useState({
    name: '',
    email: ''
  });
  const [submitStatus, setSubmitStatus] = useState('idle');
  const [isShaking, setIsShaking] = useState(false);
  const [error, setError] = useState('');

  const getMobileOperatingSystem = () => {
    const isDevMode = process.env.NODE_ENV === 'development';
    if (isDevMode) {
      const urlParams = new URLSearchParams(window.location.search);
      const deviceParam = urlParams.get('device');
      if (deviceParam) {
        return deviceParam;
      }
    }

    const userAgent = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(userAgent)) {
      return 'ios';
    } else if (/android/.test(userAgent)) {
      return 'android';
    }
    return 'desktop';
  };

  useEffect(() => {
    setDeviceType(getMobileOperatingSystem());
  }, []);

  const handleNewsletterSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus('loading');
    setError('');

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/newsletter-signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      // Check if response is ok before trying to parse JSON
      if (!response.ok) {
        const errorData = await response.text();
        console.error('Server Error:', errorData);
        throw new Error(response.status === 404 ? 'API endpoint not found' : 'Failed to subscribe to newsletter');
      }

      const data = await response.json();
      setSubmitStatus('success');

      // Reset form and status after delay
      setTimeout(() => {
        setFormData({ name: '', email: '' });
        setSubmitStatus('idle');
        setError('');
      }, 3000);

    } catch (err) {
      console.error('Newsletter Error:', err);
      setError(err.message);
      setSubmitStatus('error');
      setIsShaking(true);
      setTimeout(() => {
        setIsShaking(false);
      }, 820);
      // Also reset error state after delay
      setTimeout(() => {
        setFormData({ name: '', email: '' });
        setSubmitStatus('idle');
        setError('');
      }, 3000);
    }
  };

  return (
    <div className="flex flex-col min-h-screen relative overflow-hidden">
      <SEOHead
        title="Features & Benefits - Treasure Tracker App"
        description="Discover how Treasure Tracker helps you document, protect, and share your most valuable possessions. Explore our features for preserving family legacy."
        path="/product"
      />
      {/* Hero Section */}
      <section className="relative min-h-[90vh] pt-36 md:pt-38">
        {/* Background gradient */}
        <div
          className="absolute inset-0 bg-gradient-to-br from-blue-500/90 to-purple-500/90 backdrop-blur-sm"
          aria-hidden="true"
        />

        {/* Falling books animation background */}
        <div
          className="absolute inset-0 overflow-hidden pointer-events-none"
          aria-hidden="true"
          style={{
            background: `url("data:image/svg+xml,${encodeURIComponent(`
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
              <defs>
    <g id="book1">
      <path d="M0,0 h30 l5,2 v40 l-5,2 h-30 z" fill="rgba(255,255,255,0.1)"/>
      <path d="M0,0 v44 l2,-2 v-40 l-2,-2" fill="rgba(255,255,255,0.2)"/>
    </g>
    <g id="book2">
      <path d="M0,0 h40 l6,3 v50 l-6,3 h-40 z" fill="rgba(255,255,255,0.1)"/>
      <path d="M0,0 v56 l3,-3 v-50 l-3,-3" fill="rgba(255,255,255,0.2)"/>
    </g>
    <g id="book3">
      <path d="M0,0 h35 l4,2 v45 l-4,2 h-35 z" fill="rgba(255,255,255,0.1)"/>
      <path d="M0,0 v49 l2,-2 v-45 l-2,-2" fill="rgba(255,255,255,0.2)"/>
    </g>
    
    <!-- Animation paths -->
    <path id="path1" d="M0,0 C20,100 -10,200 30,300 C50,400 0,500 20,600" fill="none"/>
    <path id="path2" d="M100,0 C80,150 120,250 70,400 C30,500 80,600 60,700" fill="none"/>
    <path id="path3" d="M200,0 C180,100 220,200 180,300 C150,400 200,500 180,600" fill="none"/>
  </defs>
  
  <!-- Books with animations -->
  <use href="#book1">
    <animateMotion 
      dur="7s"
      repeatCount="indefinite"
      path="M0,0 C20,100 -10,200 30,300 C50,400 0,500 20,600"
    >
      <mpath href="#path1"/>
    </animateMotion>
    <animateTransform
      attributeName="transform"
      type="rotate"
      from="0 15 22"
      to="360 15 22"
      dur="7s"
      repeatCount="indefinite"
    />
  </use>
  
  <use href="#book2" transform="translate(100, -50)">
    <animateMotion 
      dur="8s"
      repeatCount="indefinite"
      path="M100,0 C80,150 120,250 70,400 C30,500 80,600 60,700"
    >
      <mpath href="#path2"/>
    </animateMotion>
    <animateTransform
      attributeName="transform"
      type="rotate"
      from="0 20 28"
      to="360 20 28"
      dur="8s"
      repeatCount="indefinite"
    />
  </use>
  
  <use href="#book3" transform="translate(200, -100)">
    <animateMotion 
      dur="6s"
      repeatCount="indefinite"
      path="M200,0 C180,100 220,200 180,300 C150,400 200,500 180,600"
    >
      <mpath href="#path3"/>
    </animateMotion>
    <animateTransform
      attributeName="transform"
      type="rotate"
      from="0 17.5 24.5"
      to="360 17.5 24.5"
      dur="6s"
      repeatCount="indefinite"
    />
  </use>
            </svg>
          `)}")`,
            backgroundSize: '1000px 1000px'
          }}
        />

        {/* Dot pattern overlay */}
        <div
          className="absolute inset-0 opacity-10"
          style={{
            backgroundImage: `radial-gradient(circle at 1px 1px, rgba(255, 255, 255, 0.15) 1px, transparent 0)`,
            backgroundSize: '20px 20px'
          }}
          aria-hidden="true"
        />

        {/* Content */}
        <div className="relative container mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            {/* Left column - Main content */}
            <div className="text-center md:text-left">
              <h1 className="text-4xl md:text-5xl lg:text-6xl font-gilmer font-bold mb-6 bg-gradient-to-r from-white via-white/90 to-white bg-clip-text text-transparent">
                Your Family's Story, Beautifully Preserved
              </h1>
              <p className="text-xl text-white/80 mb-8">
                Discover how Treasure Tracker helps you document, protect, and share your most valuable possessions.
              </p>

              {/* Download buttons */}
              <div className="flex flex-col sm:flex-row gap-4 justify-center md:justify-start mb-8">
                {deviceType === 'desktop' ? (
                  <>
                    <Link
                      to="/download"
                      className="bg-white text-blue-500 px-8 py-3 rounded-full font-medium
                             text-center transition-all duration-200 ease-out
                             hover:bg-opacity-90 hover:shadow-[0_0_20px_rgba(255,255,255,0.3)]
                             hover:scale-105"
                    >
                      Try Free Today
                    </Link>
                    <Link
                      to="/pricing"
                      className="border border-white text-white px-8 py-3 rounded-full font-medium
                             text-center transition-colors duration-200 ease-out
                             hover:bg-white/10"
                    >
                      View Pricing
                    </Link>
                  </>
                ) : deviceType === 'ios' ? (
                  <>
                    <a
                      href="https://apps.apple.com/us/app/treasure-tracker-app/id6470204759"
                      className="bg-white text-blue-500 px-8 py-3 rounded-full font-medium
                             text-center transition-all duration-200 ease-out
                             hover:bg-opacity-90 hover:shadow-[0_0_20px_rgba(255,255,255,0.3)]
                             hover:scale-105"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download for iOS
                    </a>
                    <Link
                      to="/pricing"
                      className="border border-white text-white px-8 py-3 rounded-full font-medium
                             text-center transition-colors duration-200 ease-out
                             hover:bg-white/10"
                    >
                      View Pricing
                    </Link>
                  </>
                ) : (
                  <>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.treasure.tracker.production"
                      className="bg-white text-blue-500 px-8 py-3 rounded-full font-medium
                             text-center transition-all duration-200 ease-out
                             hover:bg-opacity-90 hover:shadow-[0_0_20px_rgba(255,255,255,0.3)]
                             hover:scale-105"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download for Android
                    </a>
                    <Link
                      to="/pricing"
                      className="border border-white text-white px-8 py-3 rounded-full font-medium
                             text-center transition-colors duration-200 ease-out
                             hover:bg-white/10"
                    >
                      View Pricing
                    </Link>
                  </>
                )}
              </div>
            </div>

            {/* Right column - Newsletter signup */}
            <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-8 border border-white/20 mb-8 md:mb-0">
              <div className="space-y-4">
                <h2 className="text-xl font-gilmer font-medium text-white">Stay Updated</h2>
                <p className="text-white/80">Get tips and insights on preserving your family's legacy.</p>

                <form
                  onSubmit={handleNewsletterSubmit}
                  className={`space-y-4 ${isShaking ? 'animate-shake' : ''}`}
                >
                  <div className="space-y-4">
                    <input
                      type="text"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      placeholder="Your name"
                      className="w-full px-4 py-3 rounded-lg bg-white/5 border border-white/20 
                     text-white placeholder-white/60 focus:outline-none focus:border-white
                     focus:bg-white/10 transition-all disabled:opacity-50"
                      required
                      disabled={submitStatus === 'loading' || submitStatus === 'success'}
                    />

                    <input
                      type="email"
                      value={formData.email}
                      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      placeholder="Your email"
                      className="w-full px-4 py-3 rounded-lg bg-white/5 border border-white/20 
                     text-white placeholder-white/60 focus:outline-none focus:border-white
                     focus:bg-white/10 transition-all disabled:opacity-50"
                      required
                      disabled={submitStatus === 'loading' || submitStatus === 'success'}
                    />
                  </div>

                  <button
                    type="submit"
                    disabled={submitStatus !== 'idle'}
                    className={`w-full px-6 py-3 rounded-lg
    transition-all duration-300 flex items-center justify-center gap-2
    ${submitStatus === 'loading'
                        ? 'bg-white text-blue-500'
                        : submitStatus === 'success'
                          ? 'bg-green-500 text-white'
                          : submitStatus === 'error'
                            ? 'bg-red-500 text-white'
                            : 'bg-white text-blue-500 hover:bg-opacity-90 hover:shadow-[0_0_20px_rgba(255,255,255,0.3)]'
                      }
    disabled:opacity-50 disabled:cursor-not-allowed`}
                  >
                    {submitStatus === 'loading' ? (
                      <>
                        <Loader2 className="h-4 w-4 animate-spin" />
                        <span>Subscribing...</span>
                      </>
                    ) : submitStatus === 'success' ? (
                      <>
                        <Check className="h-4 w-4" />
                        <span>Successfully Subscribed!</span>
                      </>
                    ) : submitStatus === 'error' ? (
                      <>
                        <XCircle className="h-4 w-4" />
                        <span>{error || 'Subscription Failed'}</span>
                      </>
                    ) : (
                      <>
                        <span>Subscribe to Newsletter</span>
                        <Send className="h-4 w-4" />
                      </>
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-24 bg-gradient-to-b from-gray-50 to-white mb-24">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-gilmer font-bold mb-6 bg-gradient-to-r from-blue-700 via-purple-600 to-blue-700 bg-clip-text text-transparent">
            Key Features
          </h2>
          <p className="text-xl text-gray-600">
            Discover how Treasure Tracker helps preserve your family's legacy
          </p>
        </div>
        <FeatureCarousel />
      </section>

      {/*<DetailedFeatures />*/}

      {/* How It Works */}
      <HowItWorksSection />

      {/* Security Section */}
      <SecuritySection />

      {/* Download CTA */}
      <section className="py-16 relative overflow-hidden">
        {/* Enhanced gradient background */}
        <div className="absolute inset-0 bg-gradient-to-br from-blue-500 via-purple-500 to-blue-600" />

        {/* Animated gradient overlay */}
        <div
          className="absolute inset-0 opacity-30"
          style={{
            background: `
        radial-gradient(circle at 20% 50%, rgba(99, 102, 241, 0.4), transparent 40%),
        radial-gradient(circle at 80% 50%, rgba(147, 51, 234, 0.4), transparent 40%)
      `
          }}
        />

        {/* Dot pattern overlay with increased size */}
        <div className="absolute inset-0 opacity-10"
          style={{
            backgroundImage: `radial-gradient(circle at 1px 1px, rgba(255, 255, 255, 0.4) 1px, transparent 0)`,
            backgroundSize: '24px 24px'
          }}
        />

        <div className="relative container mx-auto px-4 text-center">
          <div className="inline-flex items-center justify-center gap-2 px-4 py-2 rounded-full bg-white/10 backdrop-blur-sm text-white mb-6">
            <Sparkles className="w-4 h-4" />
            <span className="text-sm font-medium">Start Your Journey Today</span>
          </div>

          <h2 className="text-4xl md:text-5xl font-gilmer font-bold text-white mb-6">
            Ready to Preserve Your Legacy?
          </h2>
          <p className="text-xl text-white/80 mb-12 max-w-2xl mx-auto">
            Join thousands of families who trust Treasure Tracker to protect and share their precious memories.
          </p>

          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            {deviceType === 'desktop' ? (
              <>
                <Link
                  to="/download"
                  className="bg-white text-blue-600 hover:bg-white/90 px-8 py-4 rounded-full font-gilmer 
                     transition-all duration-300 hover:shadow-[0_0_30px_rgba(255,255,255,0.3)] 
                     hover:scale-105 flex items-center justify-center gap-2"
                >
                  Get Started Free
                  <ChevronRight className="w-5 h-5" />
                </Link>
                <Link
                  to="/pricing"
                  className="border border-white text-white px-8 py-4 rounded-full font-gilmer
                     transition-all duration-300 hover:bg-white/10 flex items-center justify-center gap-2"
                >
                  View Pricing
                  <ChevronRight className="w-5 h-5" />
                </Link>
              </>
            ) : (
              <>
                <a
                  href={deviceType === 'ios'
                    ? "https://apps.apple.com/us/app/treasure-tracker-app/id6470204759"
                    : "https://play.google.com/store/apps/details?id=com.treasure.tracker.production"
                  }
                  className="bg-white text-blue-600 hover:bg-white/90 px-8 py-4 rounded-full font-gilmer
                transition-all duration-300 hover:shadow-[0_0_30px_rgba(255,255,255,0.3)]
                hover:scale-105 flex items-center justify-center gap-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download for {deviceType === 'ios' ? 'iOS' : 'Android'}
                  <ChevronRight className="w-5 h-5" />
                </a>
                <Link
                  to="/pricing"
                  className="border border-white text-white px-8 py-4 rounded-full font-gilmer
                     transition-all duration-300 hover:bg-white/10 flex items-center justify-center gap-2"
                >
                  View Pricing
                  <ChevronRight className="w-5 h-5" />
                </Link>
              </>
            )}
          </div>

          {/* Trust badges */}
          <div className="mt-12 flex flex-wrap justify-center gap-6 items-center text-white/60">
            <div className="flex items-center gap-2">
              <Shield className="w-4 h-4" />
              <span className="text-sm">Secure Storage</span>
            </div>
            <span className="w-1.5 h-1.5 rounded-full bg-white/20" />
            <div className="flex items-center gap-2">
              <Camera className="w-4 h-4" />
              <span className="text-sm">HD Quality</span>
            </div>
            <span className="w-1.5 h-1.5 rounded-full bg-white/20" />
            <div className="flex items-center gap-2">
              <Share className="w-4 h-4" />
              <span className="text-sm">Easy Sharing</span>
            </div>
          </div>
        </div>
      </section >
    </div >
  );
};

export default ProductPage;