import React from 'react';
import { Link } from 'react-router-dom'
import { Heart, Shield, Sparkles, ChevronRight, Users } from 'lucide-react';
import { Card, CardContent } from '../components/ui/Card';
import MissionSection from '../components/MissionSection';
import OriginStorySection from '../components/OriginStory';
import TeamSection from '../components/TeamSection';
import SEOHead from '../components/SEOHead';

const AboutPage = () => {
  // Ensure proper mobile viewport scaling
  React.useEffect(() => {
    const viewport = document.querySelector('meta[name=viewport]');
    if (viewport) {
      viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
    }
    return () => {
      if (viewport) {
        viewport.setAttribute('content', 'width=device-width, initial-scale=1.0');
      }
    };
  }, []);

  const values = [
    {
      icon: Heart,
      title: "Family First",
      description: "We believe in strengthening family bonds through shared stories and memories."
    },
    {
      icon: Shield,
      title: "Trust & Security",
      description: "Your family's treasures deserve the highest level of protection and care."
    },
    {
      icon: Sparkles,
      title: "Innovation",
      description: "We constantly evolve our technology to better serve families' needs."
    }
  ];

  return (
    <div className="flex flex-col min-h-screen relative">
      <SEOHead
        title="About Us - Treasure Tracker's Story & Mission"
        description="Learn about Treasure Tracker's mission to help families preserve their legacy through stories and treasured possessions. Meet the team behind the app."
        path="/about"
      />
      {/* Hero Section - Updated with responsive classes */}
      <section className="relative pt-32 md:pt-32 pb-12" aria-labelledby="about-hero-title">
        {/* Background gradient */}
        <div
          className="absolute inset-0 bg-gradient-to-br from-blue-500/90 to-purple-500/90 backdrop-blur-sm"
          aria-hidden="true"
        />

        {/* Dot pattern overlay */}
        <div
          className="absolute inset-0 opacity-10"
          style={{
            backgroundImage: `radial-gradient(circle at 1px 1px, rgba(255, 255, 255, 0.15) 1px, transparent 0)`,
            backgroundSize: '20px 20px'
          }}
          aria-hidden="true"
        />

        {/* Content */}
        <div className="relative container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <div className="inline-flex items-center justify-center gap-2 px-4 py-2 rounded-full bg-white/10 backdrop-blur-sm text-white mb-8">
              <Users className="w-4 h-4" />
              <span className="text-sm font-medium">Meet the Team Behind Treasure Tracker</span>
            </div>

            <h1
              id="about-hero-title"
              className="text-3xl md:text-4xl lg:text-6xl font-bold mb-6 
                       bg-gradient-to-r from-white via-white/90 to-white 
                       bg-clip-text text-transparent
                       [text-shadow:_0_0_30px_rgba(255,255,255,0.3)]"
            >
              Our Story
            </h1>
            <p className="text-lg md:text-xl text-white/80 mb-8 max-w-2xl mx-auto">
              Building bridges between generations through the power of shared stories and treasured memories.
            </p>
          </div>
        </div>
      </section>

      {/* Rest of the sections remain unchanged */}
      <MissionSection />
      <OriginStorySection />
      <TeamSection />

      {/* Values Section */}
      <section className="py-24 bg-gradient-to-br from-blue-500 to-purple-500" aria-labelledby="values-title">
        <div className="container mx-auto px-4">
          <h2
            id="values-title"
            className="text-4xl font-bold text-white text-center mb-16"
          >
            Our Values
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {values.map((value, index) => (
              <Card
                key={index}
                className="group hover:shadow-xl transition-all duration-300
                         bg-white/10 backdrop-blur-sm border-white/20"
              >
                <CardContent className="p-6">
                  <div className="mb-6">
                    <div className="w-12 h-12 rounded-full bg-white/10 
                                flex items-center justify-center 
                                group-hover:scale-110 transition-transform duration-300">
                      <value.icon className="w-6 h-6 text-white" />
                    </div>
                  </div>
                  <h3 className="text-xl font-bold text-white mb-3">
                    {value.title}
                  </h3>
                  <p className="text-white/80">
                    {value.description}
                  </p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Contact CTA */}
      <section className="py-24 bg-gradient-to-br from-gray-50/50 to-white relative" aria-labelledby="contact-title">
        {/* Subtle background pattern */}
        <div className="absolute inset-0 opacity-[0.015]"
          style={{
            backgroundImage: `radial-gradient(circle at 1px 1px, rgb(59 130 246) 1px, transparent 0)`,
            backgroundSize: '40px 40px'
          }}
        />

        <div className="container mx-auto px-4 text-center relative">
          <h2
            id="contact-title"
            className="text-4xl font-bold mb-6
               bg-gradient-to-r from-blue-700 via-purple-600 to-blue-700 
               bg-clip-text text-transparent"
          >
            Want to Learn More?
          </h2>
          <p className="text-xl text-gray-600 mb-8">
            We'd love to hear from you and answer any questions you might have.
          </p>
          <Link
            to="/contact"
            className="inline-flex items-center gap-2 bg-blue-600 text-white px-8 py-3 rounded-full font-medium
               transition-all duration-200 ease-out
               hover:bg-blue-500 hover:shadow-[0_0_20px_rgba(59,130,246,0.5)]
               hover:scale-105"
          >
            Contact Us
            <ChevronRight className="w-5 h-5" />
          </Link>
        </div>
      </section>
    </div>
  );
};

export default AboutPage;