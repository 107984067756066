import React, { useState, useEffect, useRef } from 'react';
import { BookOpen, Shield, Users, Play } from 'lucide-react';
import { Card, CardContent } from './ui/Card';

const MissionSection = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [animatedElements, setAnimatedElements] = useState({
    pillars: false,
    stats: false
  });

  const pillarsRef = useRef(null);
  const statsRef = useRef(null);

  useEffect(() => {
    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (entry.target === pillarsRef.current) {
            setAnimatedElements(prev => ({ ...prev, pillars: true }));
          } else if (entry.target === statsRef.current) {
            setAnimatedElements(prev => ({ ...prev, stats: true }));
          }
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.2,
      rootMargin: '0px 0px -100px 0px'
    });

    if (pillarsRef.current) observer.observe(pillarsRef.current);
    if (statsRef.current) observer.observe(statsRef.current);

    return () => observer.disconnect();
  }, []);

  const missionPillars = [
    {
      icon: BookOpen,
      title: "Story Preservation",
      description: "Every family treasure has a unique story waiting to be told and preserved for future generations."
    },
    {
      icon: Users,
      title: "Family Connection",
      description: "Bridge the gap between generations by sharing the histories and memories behind cherished possessions."
    },
    {
      icon: Shield,
      title: "Legacy Protection",
      description: "Safeguard your family's heritage with secure digital documentation and organized records."
    }
  ];

  const stats = [
    { value: "10,000+", label: "Family Stories Preserved" },
    { value: "30+", label: "Years of Combined Experience" },
    { value: "98%", label: "User Satisfaction" }
  ];

  return (
    <section className="relative" aria-labelledby="mission-title">
      <div className="absolute inset-0 bg-white" />
      <div className="absolute inset-0 bg-gradient-to-b from-purple-50/80 to-white/95 pointer-events-none" />

      <div className="relative py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            {/* Title and description remain the same */}
            <h2
              id="mission-title"
              className="text-4xl font-bold mb-6 bg-gradient-to-r from-blue-700 via-purple-600 to-blue-700 bg-clip-text text-transparent"
            >
              Our Mission
            </h2>

            <p className="text-xl text-gray-600 mb-12">
              Treasure Tracker aims to connect generations by bringing treasures to life.
              We believe every object tells a story, and every story deserves to be preserved and shared.
            </p>

            {/* Video Container */}
            <div className="relative rounded-2xl overflow-hidden shadow-xl mb-20 bg-gradient-to-br from-purple-600 to-purple-800">
              <div className="relative aspect-video rounded-2xl overflow-hidden">
                {!isVideoPlaying ? (
                  <div
                    className="absolute inset-0 cursor-pointer group"
                    onClick={() => setIsVideoPlaying(true)}
                  >
                    {/* Background gradient overlay */}
                    <div className="absolute inset-0 bg-[radial-gradient(circle_500px_at_50%_50%,rgba(255,255,255,0.1),transparent)] animate-pulse" />

                    {/* Play button */}
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="absolute w-24 h-24 rounded-full bg-white/10 animate-ping" />

                      <div className="relative w-20 h-20 rounded-full bg-white/20 backdrop-blur-sm flex items-center justify-center group-hover:scale-110 transition-all duration-300 ease-out">
                        <div className="w-16 h-16 rounded-full bg-white flex items-center justify-center shadow-lg">
                          <Play className="w-8 h-8 text-purple-600" />
                        </div>
                      </div>
                    </div>

                    {/* Mobile-optimized text placement */}
                    <div className="absolute bottom-0 left-0 right-0 text-center bg-gradient-to-t from-purple-900/80 via-purple-900/60 to-transparent pt-8 pb-4 px-4">
                      <h3 className="text-white text-lg font-medium mb-4">
                        Our Mission in Action
                      </h3>
                    </div>
                  </div>
                ) : (
                  <iframe
                    className="absolute inset-0 w-full h-full"
                    src="https://www.youtube.com/embed/0eeDrJkYDn8?autoplay=1&rel=0"
                    title="Treasure Tracker Mission"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                )}
              </div>
            </div>

            {/* Mission Pillars */}
            <div ref={pillarsRef} className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-20">
              {missionPillars.map((pillar, index) => (
                <div
                  key={index}
                  className={`transition-all duration-700 ease-out
                           ${animatedElements.pillars
                      ? 'opacity-100 translate-x-0'
                      : 'opacity-0 -translate-x-20'}`}
                  style={{ transitionDelay: `${index * 150}ms` }}
                >
                  <Card className="h-full hover:shadow-lg transition-all duration-300">
                    <CardContent className="p-6 text-center">
                      <div className="mb-4">
                        <div className="w-12 h-12 mx-auto rounded-full bg-purple-100 
                                    flex items-center justify-center">
                          <pillar.icon className="w-6 h-6 text-purple-600" />
                        </div>
                      </div>
                      <h3 className="text-lg font-semibold text-gray-900 mb-2">
                        {pillar.title}
                      </h3>
                      <p className="text-gray-600">
                        {pillar.description}
                      </p>
                    </CardContent>
                  </Card>
                </div>
              ))}
            </div>

            {/* Stats */}
            <div ref={statsRef} className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {stats.map((stat, index) => (
                <div
                  key={index}
                  className={`transition-all duration-700 ease-out
                           ${animatedElements.stats
                      ? 'opacity-100 translate-x-0'
                      : 'opacity-0 translate-x-20'}`}
                  style={{ transitionDelay: `${index * 150}ms` }}
                >
                  <div className="bg-white/60 backdrop-blur-sm rounded-lg p-6">
                    <div className="text-4xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 
                                bg-clip-text text-transparent mb-2">
                      {stat.value}
                    </div>
                    <div className="text-gray-600">
                      {stat.label}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionSection;