import React, { useState } from 'react';
import { Phone, Mail, MapPin, MessageSquare, ChevronRight, Check, Loader2, XCircle, Send } from 'lucide-react';
import { Card, CardContent } from '../components/ui/Card';
import SEOHead from '../components/SEOHead';

const Checkbox = ({ checked, onChange, id, className, disabled }) => {
  return (
    <div
      onClick={() => !disabled && onChange(!checked)}
      className={`w-5 h-5 rounded border cursor-pointer flex items-center justify-center
                 transition-all duration-200 ${checked
          ? 'bg-blue-600 border-blue-600'
          : 'border-gray-300 hover:border-blue-500'
        } ${disabled ? 'opacity-50 cursor-not-allowed' : ''} ${className}`}
    >
      {checked && <Check className="h-3.5 w-3.5 text-white" />}
    </div>
  );
};

const ContactPage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    subject: 'General Inquiry',
    message: '',
    newsletter: true
  });

  const [submitStatus, setSubmitStatus] = useState('idle');
  const [isShaking, setIsShaking] = useState(false);
  const [error, setError] = useState('');

  const resetForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      subject: 'General Inquiry',
      message: '',
      newsletter: true
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus('loading');
    setError('');

    try {
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: `${formData.firstName} ${formData.lastName}`,
          email: formData.email,
          subject: formData.subject,
          message: formData.message,
          newsletter: formData.newsletter
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to submit form');
      }

      setSubmitStatus('success');
      resetForm();

      // Reset form and status after delay
      setTimeout(() => {
        setSubmitStatus('idle');
        setError('');
      }, 3000);

    } catch (err) {
      console.error('Contact Form Error:', err);
      setError(err.message);
      setSubmitStatus('error');
      setIsShaking(true);

      setTimeout(() => {
        setIsShaking(false);
      }, 820);

      // Reset error state after delay
      setTimeout(() => {
        setSubmitStatus('idle');
        setError('');
      }, 3000);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <SEOHead
        title="Contact Us | Treasure Tracker Support"
        description="Get in touch with the Treasure Tracker team. We're here to help you with any questions about preserving your family's legacy."
        path="/contact"
      />
      {/* Hero Section */}
      <section className="relative pt-32 pb-20" aria-labelledby="contact-hero-title">
        {/* Background gradient */}
        <div
          className="absolute inset-0 bg-gradient-to-br from-blue-500/90 to-purple-500/90 backdrop-blur-sm"
          aria-hidden="true"
        />

        {/* Dot pattern overlay */}
        <div
          className="absolute inset-0 opacity-10"
          style={{
            backgroundImage: `radial-gradient(circle at 1px 1px, rgba(255, 255, 255, 0.15) 1px, transparent 0)`,
            backgroundSize: '20px 20px'
          }}
          aria-hidden="true"
        />

        {/* Content */}
        <div className="relative container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <div className="inline-flex items-center justify-center gap-2 px-4 py-2 rounded-full bg-white/10 backdrop-blur-sm text-white mb-8">
              <MessageSquare className="w-4 h-4" />
              <span className="text-sm font-medium">We'd Love to Hear from You</span>
            </div>

            <h1
              id="contact-hero-title"
              className="text-4xl md:text-5xl lg:text-6xl font-gilmer font-bold mb-6
                       bg-gradient-to-r from-white via-white/90 to-white
                       bg-clip-text text-transparent
                       [text-shadow:_0_0_30px_rgba(255,255,255,0.3)]"
            >
              Get in Touch
            </h1>
            <p className="text-xl text-white/80 mb-8">
              Have questions? We'd love to hear from you. Send us a message and we'll respond as soon as possible.
            </p>
          </div>
        </div>
      </section>

      {/* Contact Options */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <div className="max-w-6xl mx-auto">
            <div className="grid md:grid-cols-3 gap-8 mb-16">
              {[
                {
                  icon: <Phone className="h-8 w-8 text-blue-600" />,
                  title: "Phone",
                  info: "303-241-7833",
                  action: "Call us",
                  href: "tel:303-241-7833"
                },
                {
                  icon: <Mail className="h-8 w-8 text-blue-600" />,
                  title: "Email",
                  info: "jackson.white@mytreasuretracker.com",
                  action: "Email us",
                  href: "mailto:jackson.white@mytreasuretracker.com"
                },
                {
                  icon: <MapPin className="h-8 w-8 text-blue-600" />,
                  title: "Office",
                  info: "Colorado, United States",
                  action: "Get directions",
                  href: "https://maps.google.com"
                }
              ].map((contact, index) => (
                <Card key={index} className="group hover:shadow-xl transition-all duration-300 border-none">
                  <CardContent className="p-6 text-center">
                    <div className="mb-4 flex justify-center">
                      <div className="p-4 rounded-xl bg-gradient-to-br from-blue-50 to-purple-50 
                    group-hover:scale-110 transition-transform duration-300
                    shadow-lg shadow-blue-500/5 border border-blue-100/20">
                        {contact.icon}
                      </div>
                    </div>
                    <h3 className="text-xl font-gilmer font-bold mb-2 
                 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent
                 group-hover:from-blue-500 group-hover:to-purple-500 transition-all">
                      {contact.title}
                    </h3>
                    <p className="text-gray-600 mb-4">{contact.info}</p>
                    <a
                      href={contact.href}
                      target={contact.title === 'Office' ? '_blank' : undefined}
                      rel={contact.title === 'Office' ? 'noopener noreferrer' : undefined}
                      className="inline-flex items-center gap-2 text-blue-600 hover:text-blue-700 font-gilmer
                               transition-all duration-300 group-hover:translate-x-1"
                    >
                      {contact.action}
                      <ChevronRight className="w-4 h-4" />
                    </a>
                  </CardContent>
                </Card>
              ))}
            </div>

            {/* Contact Form */}
            <div className="max-w-3xl mx-auto">
              <Card className="border-none shadow-xl">
                <CardContent className="p-8">
                  <div className="flex items-center justify-center gap-2 mb-8">
                    <h2 className="text-2xl md:text-3xl font-gilmer font-bold text-center
                 bg-gradient-to-r from-blue-700 via-purple-600 to-blue-700 
                 bg-clip-text text-transparent">
                      Send Us a Message
                    </h2>
                  </div>

                  <form onSubmit={handleSubmit} className={`space-y-6 ${isShaking ? 'animate-shake' : ''}`}>
                    <div className="grid md:grid-cols-2 gap-6">
                      <div>
                        <label className="block text-gray-700 mb-2 font-gilmer">First Name</label>
                        <input
                          type="text"
                          value={formData.firstName}
                          onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                          className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:border-blue-500 
                             focus:ring-2 focus:ring-blue-500/20 focus:outline-none transition-all"
                          required
                          disabled={submitStatus !== 'idle'}
                        />
                      </div>
                      <div>
                        <label className="block text-gray-700 mb-2 font-gilmer">Last Name</label>
                        <input
                          type="text"
                          value={formData.lastName}
                          onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                          className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:border-blue-500 
                             focus:ring-2 focus:ring-blue-500/20 focus:outline-none transition-all"
                          required
                          disabled={submitStatus !== 'idle'}
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-gray-700 mb-2 font-gilmer">Email</label>
                      <input
                        type="email"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:border-blue-500 
                           focus:ring-2 focus:ring-blue-500/20 focus:outline-none transition-all"
                        required
                        disabled={submitStatus !== 'idle'}
                      />
                    </div>

                    <div>
                      <label className="block text-gray-700 mb-2 font-gilmer">Subject</label>
                      <select
                        value={formData.subject}
                        onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
                        className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:border-blue-500 
                           focus:ring-2 focus:ring-blue-500/20 focus:outline-none transition-all"
                        disabled={submitStatus !== 'idle'}
                      >
                        <option>General Inquiry</option>
                        <option>Partnership Opportunity</option>
                        <option>Technical Support</option>
                        <option>Other</option>
                      </select>
                    </div>

                    <div>
                      <label className="block text-gray-700 mb-2 font-gilmer">Message</label>
                      <textarea
                        rows="5"
                        value={formData.message}
                        onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                        className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:border-blue-500 
                           focus:ring-2 focus:ring-blue-500/20 focus:outline-none transition-all resize-none"
                        required
                        disabled={submitStatus !== 'idle'}
                      ></textarea>
                    </div>

                    <div className="flex items-center gap-2">
                      <Checkbox
                        id="newsletter"
                        checked={formData.newsletter}
                        onChange={(checked) => setFormData({ ...formData, newsletter: checked })}
                        disabled={submitStatus !== 'idle'}
                      />
                      <label
                        htmlFor="newsletter"
                        className="text-sm text-gray-600 cursor-pointer select-none"
                      >
                        Subscribe to our newsletter for tips and insights about preserving family treasures
                      </label>
                    </div>

                    <button
                      type="submit"
                      disabled={submitStatus !== 'idle'}
                      className={`w-full px-6 py-3 rounded-full
                  transition-all duration-300 flex items-center justify-center gap-2
                  ${submitStatus === 'loading'
                          ? 'bg-blue-100 text-blue-500'
                          : submitStatus === 'success'
                            ? 'bg-green-500 text-white'
                            : submitStatus === 'error'
                              ? 'bg-red-500 text-white'
                              : 'bg-gradient-to-r from-blue-600 to-purple-600 text-white hover:from-blue-500 hover:to-purple-500'
                        }
                  disabled:opacity-50 disabled:cursor-not-allowed
                  hover:shadow-lg hover:scale-[1.02]
                  disabled:hover:scale-100 disabled:hover:shadow-none`}
                    >
                      {submitStatus === 'loading' ? (
                        <>
                          <Loader2 className="h-4 w-4 animate-spin" />
                          <span>Sending...</span>
                        </>
                      ) : submitStatus === 'success' ? (
                        <>
                          <Check className="h-4 w-4" />
                          <span>Message Sent!</span>
                        </>
                      ) : submitStatus === 'error' ? (
                        <>
                          <XCircle className="h-4 w-4" />
                          <span>{error || 'Failed to Send'}</span>
                        </>
                      ) : (
                        <>
                          <Send className="h-4 w-4" />
                          <span>Send Message</span>
                        </>
                      )}
                    </button>
                  </form>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-20 bg-gradient-to-br from-gray-50/50 to-white relative">
        {/* Subtle background pattern */}
        <div className="absolute inset-0 opacity-[0.015]"
          style={{
            backgroundImage: `radial-gradient(circle at 1px 1px, rgb(59 130 246) 1px, transparent 0)`,
            backgroundSize: '40px 40px'
          }}
        />

        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-gilmer font-bold text-center mb-16
                        bg-gradient-to-r from-blue-700 via-purple-600 to-blue-700 
                        bg-clip-text text-transparent">
            Frequently Asked Questions
          </h2>
          <div className="max-w-3xl mx-auto grid gap-8">
            {[
              {
                question: "How quickly can I expect a response?",
                answer: "We typically respond to all inquiries within 24 hours during business days."
              },
              {
                question: "Do you offer technical support?",
                answer: "Yes, we provide technical support to all our users. Premium users get priority support."
              },
              {
                question: "Can I schedule a demo?",
                answer: "Yes! You can schedule a demo through our partnership page or by contacting us directly."
              }
            ].map((faq, index) => (
              <Card key={index} className="group hover:shadow-lg transition-all duration-300 border-none">
                <CardContent className="p-6">
                  <h3 className="text-xl font-gilmer font-bold text-gray-900 mb-2 
                               group-hover:text-blue-600 transition-colors">
                    {faq.question}
                  </h3>
                  <p className="text-gray-600">{faq.answer}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactPage;