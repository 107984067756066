import React, { useState, useEffect } from 'react';
import { ArrowRight, Clock, User, Send, Check, XCircle, Star, Loader2 } from 'lucide-react';
import { Card, CardContent } from '../components/ui/Card';
import { Link } from 'react-router-dom';
import SEOHead from '../components/SEOHead';

const BlogPage = () => {
  const [deviceType, setDeviceType] = useState('desktop');
  const [formData, setFormData] = useState({ name: '', email: '' });
  const [submitStatus, setSubmitStatus] = useState('idle');
  const [isShaking, setIsShaking] = useState(false);
  const [blogPosts, setBlogPosts] = useState([]);
  const [featuredPost, setFeaturedPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getMobileOperatingSystem = () => {
    const isDevMode = process.env.NODE_ENV === 'development';
    if (isDevMode) {
      const urlParams = new URLSearchParams(window.location.search);
      const deviceParam = urlParams.get('device');
      if (deviceParam) {
        return deviceParam;
      }
    }

    const userAgent = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(userAgent)) {
      return 'ios';
    } else if (/android/.test(userAgent)) {
      return 'android';
    }
    return 'desktop';
  };

  useEffect(() => {
    setDeviceType(getMobileOperatingSystem());
  }, []);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/blog-posts`);

        if (!response.ok) {
          const text = await response.text();
          console.error('Error response:', text);
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.data) {
          // Format the posts data
          const formattedPosts = data.data.map(post => ({
            id: post.id,
            title: post.Title, // Changed from post.attributes.title
            excerpt: post.Description, // Changed from post.attributes.description
            content: post.Content, // Changed from post.attributes.content
            category: post.Tags, // Changed from post.attributes.category
            author: post.Author, // Changed from post.attributes.author
            date: new Date(post.PublishedDate || post.publishedAt).toLocaleDateString(),
            readTime: `${Math.ceil((post.Content?.length || 0) / 200)} min read`,
            featured: post.Featured, // Changed from post.attributes.featured
            coverImage: post.CoverImage?.url, // Adjust based on your image structure
            slug: post.Slug // Changed from post.attributes.slug
          }));

          // Separate featured post
          const featured = formattedPosts.find(post => post.featured);
          const regular = formattedPosts.filter(post => !post.featured);

          setFeaturedPost(featured || formattedPosts[0]);
          setBlogPosts(regular);
        }
      } catch (err) {
        setError('Failed to fetch blog posts');
        console.error('Error fetching blog posts:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPosts();
  }, []);

  const handleNewsletterSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus('loading'); // Add loading state

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/newsletter-signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.text();
        console.error('Server Error:', errorData);
        throw new Error(response.status === 404 ? 'API endpoint not found' : 'Failed to subscribe to newsletter');
      }

      const data = await response.json();
      setSubmitStatus('success');

      // Reset form after success (keeping existing timeout)
      setTimeout(() => {
        setFormData({ name: '', email: '' });
        setSubmitStatus('idle');
      }, 3000);

    } catch (error) {
      console.error('Newsletter Error:', error);
      setSubmitStatus('error');
      setIsShaking(true);
      setTimeout(() => {
        setIsShaking(false);
        setSubmitStatus('idle');
      }, 2000);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen">
      <SEOHead
        title="Blog | Tips & Stories About Family Legacy - Treasure Tracker"
        description="Discover stories, tips, and insights about preserving family treasures and memories. Learn how to document and share your family's unique history with Treasure Tracker."
        path="/blog"
        type="blog"
      />
      {/* Hero Section */}
      <section className="relative pt-32 pb-20" aria-labelledby="blog-hero-title">
        {/* Background gradient */}
        <div
          className="absolute inset-0 bg-gradient-to-br from-blue-500/90 to-purple-500/90 backdrop-blur-sm"
          aria-hidden="true"
        />

        {/* Dot pattern overlay */}
        <div
          className="absolute inset-0 opacity-10"
          style={{
            backgroundImage: `radial-gradient(circle at 1px 1px, rgba(255, 255, 255, 0.15) 1px, transparent 0)`,
            backgroundSize: '20px 20px'
          }}
          aria-hidden="true"
        />

        {/* Content */}
        <div className="relative container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <div className="inline-flex items-center justify-center gap-2 px-4 py-2 rounded-full bg-white/10 backdrop-blur-sm text-white mb-8">
              <span className="text-sm font-medium">Insights & Stories</span>
            </div>

            <h1
              id="blog-hero-title"
              className="text-4xl md:text-5xl lg:text-6xl font-gilmer font-bold pb-6
                       bg-gradient-to-r from-white via-white/90 to-white
                       bg-clip-text text-transparent
                       [text-shadow:_0_0_30px_rgba(255,255,255,0.3)]"
            >
              Treasure Tracker Blog
            </h1>
            <p className="text-xl text-white/80 mb-8">
              Stories, tips, and insights about preserving family treasures and memories.
            </p>

            {/* CTA Buttons */}
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              {deviceType === 'desktop' ? (
                <Link
                  to="/download"
                  className="bg-white text-blue-600 hover:bg-white/90 px-8 py-3 rounded-full font-gilmer
                           transition-all duration-300 hover:shadow-[0_0_30px_rgba(255,255,255,0.3)]
                           hover:scale-105"
                >
                  Try Free Today
                </Link>
              ) : deviceType === 'ios' ? (
                <a
                  href="https://apps.apple.com/us/app/treasure-tracker-app/id6470204759"
                  className="bg-white text-blue-600 hover:bg-white/90 px-8 py-3 rounded-full font-gilmer
                           transition-all duration-300 hover:shadow-[0_0_30px_rgba(255,255,255,0.3)]
                           hover:scale-105"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download for iOS
                </a>
              ) : (
                <a
                  href="https://play.google.com/store/apps/details?id=com.treasure.tracker.production"
                  className="bg-white text-blue-600 hover:bg-white/90 px-8 py-3 rounded-full font-gilmer
                           transition-all duration-300 hover:shadow-[0_0_30px_rgba(255,255,255,0.3)]
                           hover:scale-105"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download for Android
                </a>
              )}
              <Link
                to="/contact"
                className="border border-white text-white px-8 py-3 rounded-full font-gilmer
                         transition-all duration-300 hover:bg-white/10"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Featured Post */}
      {featuredPost && (
        <section className="py-20">
          <div className="container mx-auto px-4">
            <div className="max-w-6xl mx-auto">
              <Card className="relative overflow-hidden hover:shadow-lg transition-shadow 
                             bg-gradient-to-br from-white via-white to-purple-50/30">
                {featuredPost.featured && (
                  <div className="absolute top-4 right-4 z-10 flex items-center gap-2 px-3 py-1 
                    bg-white/90 backdrop-blur-sm rounded-full shadow-sm">
                    <Star className="h-4 w-4 text-yellow-500 fill-yellow-500" />
                    <span className="text-sm font-medium text-gray-800">Featured</span>
                  </div>
                )}
                <div className="grid md:grid-cols-2 gap-8">
                  <Link to={`/blog/${featuredPost.slug}`} className="block transition-all duration-300 hover:opacity-90">

                    <div className="relative aspect-video overflow-hidden rounded-l-lg">
                      {featuredPost.coverImage ? (
                        <img
                          src={`${process.env.REACT_APP_STRAPI_URL}${featuredPost.coverImage}`}
                          alt={featuredPost.title}
                          className="object-cover w-full h-full"
                        />
                      ) : (
                        <div className="absolute inset-0 bg-gradient-to-br from-blue-200 via-purple-100 to-blue-100" />
                      )}
                    </div>
                  </Link>
                  <div className="p-8">
                    <span className="inline-block px-4 py-1 bg-gradient-to-r from-blue-100 to-purple-100 
                                   text-blue-600 rounded-full text-sm font-gilmer mb-4">
                      {featuredPost.category}
                    </span>
                    <Link to={`/blog/${featuredPost.slug}`} className="block transition-all duration-300 hover:opacity-90">

                      <h2 className="text-3xl font-gilmer font-bold 
             bg-gradient-to-r from-blue-600 to-purple-600 
             bg-clip-text text-transparent 
             hover:from-purple-600 hover:to-blue-600
             transition-all duration-300 mb-4">
                        {featuredPost.title}
                      </h2>
                    </Link>
                    <p className="text-gray-600 mb-6">{featuredPost.excerpt}</p>

                    <div className="flex items-center gap-6 text-sm text-gray-500 mb-8">
                      <div className="flex items-center gap-2">
                        <User className="h-4 w-4" />
                        {featuredPost.author}
                      </div>
                      <div className="flex items-center gap-2">
                        <Clock className="h-4 w-4" />
                        {featuredPost.readTime}
                      </div>
                    </div>

                    <Link
                      to={`/blog/${featuredPost.slug}`}
                      className="group flex items-center gap-2 px-6 py-2 bg-gradient-to-r 
                               from-blue-500 to-purple-500 text-white rounded-full font-gilmer 
                               transition-all duration-300 hover:shadow-lg hover:scale-105"
                    >
                      Read More
                      <ArrowRight className="h-4 w-4 transform transition-transform duration-300 
                                           group-hover:translate-x-1" />
                    </Link>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </section>
      )}

      {/* Blog Posts Grid */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="max-w-6xl mx-auto">
            {/* Posts Grid */}
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {blogPosts.map((post, index) => (
                <Card key={index} className="overflow-hidden hover:shadow-lg transition-all duration-300 hover:-translate-y-1">
                  <Link to={`/blog/${post.slug}`} className="block transition-all duration-300 hover:opacity-90">
                    <div className="aspect-video bg-gradient-to-br from-blue-50 to-purple-50">
                      {post.coverImage ? (
                        <img
                          src={`${process.env.REACT_APP_STRAPI_URL}${post.coverImage}`}
                          alt={post.title}
                          className="w-full h-full object-cover"
                        />
                      ) : (
                        <div className="w-full h-full bg-gradient-to-br from-blue-100 to-purple-100" />
                      )}
                    </div>
                  </Link>
                  <CardContent className="p-6">

                    <span className="inline-block px-3 py-1 bg-purple-100 text-purple-600 rounded-full text-sm font-gilmer mb-4">
                      {post.category}
                    </span>
                    <Link to={`/blog/${post.slug}`} className="block transition-all duration-300 hover:opacity-90">
                      <h3 className="text-2xl font-gilmer font-bold 
             bg-gradient-to-r from-blue-600 to-purple-600 
             bg-clip-text text-transparent 
             hover:from-purple-600 hover:to-blue-600
             transition-all duration-300 mb-4">
                        {post.title}
                      </h3>
                    </Link>
                    <p className="text-gray-600 mb-6">
                      {post.excerpt}
                    </p>
                    <div className="flex items-center justify-between text-sm text-gray-500">
                      <div className="flex items-center gap-2">
                        <User className="h-4 w-4" />
                        {post.author}
                      </div>
                      <div className="flex items-center gap-2">
                        <Clock className="h-4 w-4" />
                        {post.readTime}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Newsletter Section */}
      <section className="py-20 bg-gradient-to-r from-blue-500 to-purple-500">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-gilmer font-bold text-white mb-6">
            Stay Updated
          </h2>
          <p className="text-xl text-white/80 mb-8 max-w-2xl mx-auto">
            Subscribe to our newsletter for the latest articles, tips, and stories.
          </p>
          <div className="max-w-md mx-auto">
            <form
              onSubmit={handleNewsletterSubmit}
              className={`space-y-4 ${isShaking ? 'animate-shake' : ''}`}
            >
              <div className="space-y-4">
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  placeholder="Your name"
                  className="w-full px-4 py-3 rounded-lg bg-white/5 border border-white/20 
                           text-white placeholder-white/60 focus:outline-none focus:border-white
                           focus:bg-white/10 transition-all"
                  required
                />
                <input
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  placeholder="Your email"
                  className="w-full px-4 py-3 rounded-lg bg-white/5 border border-white/20 
                           text-white placeholder-white/60 focus:outline-none focus:border-white
                           focus:bg-white/10 transition-all"
                  required
                />
              </div>
              <div className="relative">
                <button
                  type="submit"
                  disabled={submitStatus !== 'idle'}
                  className={`w-full px-6 py-3 rounded-lg
    transition-all duration-300 flex items-center justify-center gap-2
    ${submitStatus === 'loading'
                      ? 'bg-white text-blue-500'
                      : submitStatus === 'success'
                        ? 'bg-green-500 text-white'
                        : submitStatus === 'error'
                          ? 'bg-red-500 text-white'
                          : 'bg-white text-blue-500 hover:bg-opacity-90 hover:shadow-[0_0_20px_rgba(255,255,255,0.3)]'
                    }
    disabled:opacity-50 disabled:cursor-not-allowed`}
                >
                  {submitStatus === 'loading' ? (
                    <>
                      <Loader2 className="h-4 w-4 animate-spin" />
                      <span>Subscribing...</span>
                    </>
                  ) : submitStatus === 'success' ? (
                    <>
                      <Check className="h-4 w-4" />
                      <span>Successfully Subscribed!</span>
                    </>
                  ) : submitStatus === 'error' ? (
                    <>
                      <XCircle className="h-4 w-4" />
                      <span>{error || 'Subscription Failed'}</span>
                    </>
                  ) : (
                    <>
                      <span>Subscribe to Newsletter</span>
                      <Send className="h-4 w-4" />
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogPage;