import { useState, useEffect, useCallback } from 'react';
import { ChevronLeft, ChevronRight, Quote, MapPin, Award, Star } from 'lucide-react';
import { Card, CardContent } from '../components/ui/Card';

const TestimonialsSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);
  const [hasFocus, setHasFocus] = useState(false);

  const testimonials = [
    {
      quote: "Finally, a beautiful way to preserve our family's history. The app has transformed how we document our treasures.",
      author: "Sarah M.",
      role: "Family Historian",
      location: "Boston, MA"
    },
    {
      quote: "Makes insurance claims and estate planning so much easier. Everything is organized and accessible.",
      author: "Michael R.",
      role: "Homeowner",
      location: "Chicago, IL"
    },
    {
      quote: "The perfect way to share our collection with the kids. They love discovering the stories behind each piece.",
      author: "Jennifer L.",
      role: "Art Collector",
      location: "San Francisco, CA"
    },
    {
      quote: "A game-changer for cataloging family heirlooms. The interface is intuitive and the story-sharing feature is brilliant.",
      author: "David P.",
      role: "Genealogist",
      location: "Austin, TX"
    },
    {
      quote: "Helps us keep track of valuables while preserving memories. It's like a digital time capsule for our family.",
      author: "Maria C.",
      role: "Antique Collector",
      location: "Denver, CO"
    }
  ];

  const minSwipeDistance = 50;
  const totalTestimonials = testimonials.length;
  const autoPlayInterval = 5000;

  const calculateRotation = (index) => {
    const baseAngle = (360 / totalTestimonials);
    let rotation = ((index - currentIndex) * baseAngle) % 360;
    if (rotation > 180) rotation -= 360;
    if (rotation < -180) rotation += 360;
    return rotation;
  };

  const calculateZIndex = (index) => {
    const diff = Math.abs(index - currentIndex);
    return totalTestimonials - diff;
  };

  const handlePrevious = useCallback(() => {
    setCurrentIndex((prev) => (prev === 0 ? totalTestimonials - 1 : prev - 1));
    setIsAutoPlaying(false);
  }, [totalTestimonials]);

  const handleNext = useCallback(() => {
    setCurrentIndex((prev) => (prev === totalTestimonials - 1 ? 0 : prev + 1));
    setIsAutoPlaying(false);
  }, [totalTestimonials]);

  const handleCardTap = useCallback((e) => {
    // Prevent default behavior
    e.preventDefault();
    e.stopPropagation();
    
    // Simply advance to next slide
    handleNext();
    setIsAutoPlaying(false);
  }, [handleNext]);

  // Keyboard navigation
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (!hasFocus) return;
      if (e.key === 'ArrowLeft') {
        handlePrevious();
        e.preventDefault();
      }
      if (e.key === 'ArrowRight') {
        handleNext();
        e.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [handlePrevious, handleNext, hasFocus]);

  // Auto-play functionality
  useEffect(() => {
    if (!isAutoPlaying) return;
    const interval = setInterval(handleNext, autoPlayInterval);
    return () => clearInterval(interval);
  }, [isAutoPlaying, handleNext]);

  return (
    <section
      className="py-24 bg-gradient-to-b from-gray-50 to-white overflow-hidden"
      aria-label="Customer Testimonials"
    >
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center">
          <h2
            className="text-4xl md:text-5xl font-bold pb-3 leading-tight
                    bg-gradient-to-r from-blue-700 via-purple-600 to-blue-700 
                    bg-clip-text text-transparent"
            id="testimonials-title"
          >
            Trusted by Families Everywhere
          </h2>
          <p className="text-xl text-gray-600">
            Join hundreds of families preserving their legacy
          </p>
        </div>

        <div className="relative h-[700px] w-full max-w-7xl mx-auto"
          role="region"
          aria-roledescription="carousel"
          aria-label="Testimonials carousel"
          tabIndex="0"
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          onMouseEnter={() => setIsAutoPlaying(false)}
          onMouseLeave={() => setIsAutoPlaying(true)}
        >
          <div className="absolute inset-0 perspective-[1500px]">
            <div className="relative w-full h-full">
              {testimonials.map((testimonial, index) => {
                const rotation = calculateRotation(index);
                const zIndex = calculateZIndex(index);
                const isActive = index === currentIndex;
                const isVisible = Math.abs(index - currentIndex) <= 2;

                return (
                  <Card
                    key={index}
                    className={`absolute left-1/2 top-1/2 w-[380px] h-[480px]
                              transition-all duration-700 ease-out cursor-pointer
                              active:scale-95 hover:scale-105
                              ${isActive ? 'touch-none' : 'touch-pan-y'}
                              ${isActive ? 'shadow-2xl' : 'shadow-xl'}`}
                    onClick={handleCardTap}
                    onTouchEnd={handleCardTap}
                    style={{
                      transform: `
                        translate(-50%, -50%)
                        rotateY(${rotation}deg)
                        translateZ(400px)
                        ${isActive ? 'scale(1.1)' : 'scale(0.9)'}
                      `,
                      zIndex,
                      opacity: isVisible ? 1 : 0.3,
                      filter: isActive ? 'none' : 'blur(2px)',
                    }}
                  >
                    <CardContent className="h-full p-8 flex flex-col justify-between relative">
                      <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-blue-500/5 to-purple-500/5 rounded-full -mr-16 -mt-16" />
                      <div className="absolute bottom-0 left-0 w-24 h-24 bg-gradient-to-tr from-blue-500/5 to-purple-500/5 rounded-full -ml-12 -mb-12" />

                      <div className="flex-1 relative">
                        <div className="absolute -top-2 -left-2 p-2 rounded-full bg-gradient-to-r from-blue-100/50 to-purple-100/50">
                          <Quote className="w-6 h-6 text-blue-500/40" aria-hidden="true" />
                        </div>

                        <div className="flex gap-1 mb-4 mt-6" aria-label="5 star rating">
                          {[...Array(5)].map((_, i) => (
                            <Star key={i} className="w-5 h-5 fill-blue-500 text-blue-500" aria-hidden="true" />
                          ))}
                        </div>

                        <p className="text-lg text-gray-700 leading-relaxed relative">
                          {testimonial.quote}
                        </p>
                      </div>

                      <div className="relative mt-6 pt-6 border-t border-gray-100/50">
                        <div className="flex items-start gap-3">
                          <div className="w-12 h-12 rounded-full bg-gradient-to-br from-blue-500 to-purple-500 flex items-center justify-center text-white font-bold text-lg">
                            {testimonial.author.split(' ')[0][0]}
                          </div>
                          <div className="flex-1">
                            <div className="font-bold text-blue-600 flex items-center gap-2">
                              {testimonial.author}
                              <Award className="w-4 h-4 text-blue-400" aria-hidden="true" />
                            </div>
                            <div className="text-gray-500">
                              {testimonial.role}
                            </div>
                            <div className="text-sm text-gray-400 flex items-center gap-1 mt-1">
                              <MapPin className="w-3 h-3" aria-hidden="true" />
                              {testimonial.location}
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                );
              })}
            </div>
          </div>

          <div className="absolute -bottom-16 left-1/2 -translate-x-1/2 flex items-center gap-8">
            <button
              onClick={handlePrevious}
              className="p-3 rounded-full text-blue-600 hover:bg-blue-50 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-400"
              aria-label="Previous testimonial"
            >
              <ChevronLeft className="w-8 h-8" aria-hidden="true" />
            </button>

            <div className="flex gap-2" role="tablist">
              {testimonials.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentIndex(index)}
                  className={`w-2 h-2 rounded-full transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-blue-400 ${
                    index === currentIndex
                      ? 'bg-blue-600 w-6'
                      : 'bg-gray-300 hover:bg-blue-400'
                  }`}
                  role="tab"
                  aria-selected={index === currentIndex}
                  aria-label={`Go to testimonial ${index + 1}`}
                />
              ))}
            </div>

            <button
              onClick={handleNext}
              className="p-3 rounded-full text-blue-600 hover:bg-blue-50 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-400"
              aria-label="Next testimonial"
            >
              <ChevronRight className="w-8 h-8" aria-hidden="true" />
            </button>
          </div>

          <div className="sr-only" aria-live="polite">
            {`Showing testimonial ${currentIndex + 1} of ${testimonials.length}`}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;