import React from 'react';
import { Shield, Lock } from 'lucide-react';
import useTermlyEmbed from '../components/useTermlyEmbed';
import SEOHead from '../components/SEOHead';

function PrivacyPolicy() {
  const policyId = '81c977b1-ec74-4a3a-9c7a-08b43180da3f';
  const { isLoaded, error, reload } = useTermlyEmbed(policyId);

  const AdBlockerHelp = () => (
    <div className="p-4 mb-4 bg-blue-50 rounded-lg text-center">
      <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-blue-100 mb-3">
        <Shield className="w-6 h-6 text-blue-500" />
      </div>
      <h3 className="text-base font-medium text-gray-900 mb-2">
        Having trouble viewing our policy?
      </h3>
      <p className="text-sm text-gray-600 mb-3">
        If our policy isn't loading, please try:
      </p>
      <ul className="text-sm text-gray-500 space-y-1 mb-3">
        <li>1. Disabling your ad blocker for this site</li>
        <li>2. Switching to private/incognito browsing</li>
        <li>3. Using a different browser</li>
      </ul>
      <button
        onClick={reload}
        className="px-4 py-2 bg-blue-500 text-white text-sm rounded-md hover:bg-blue-600 transition-colors"
      >
        Refresh Page
      </button>
    </div>
  );

  return (
    <div className="flex flex-col min-h-screen">
      <SEOHead
        title="Privacy Policy - Treasure Tracker"
        description="Read about how Treasure Tracker protects your data and respects your privacy. Learn about our data collection, usage, and protection practices."
        path="/privacy"
        type="website"
      />
      {/* Hero Section */}
      <section className="relative pt-32 pb-20">
        {/* Background gradient */}
        <div className="absolute inset-0 bg-gradient-to-br from-blue-500/90 to-purple-500/90" />

        {/* Dot pattern overlay */}
        <div className="absolute inset-0 opacity-10"
          style={{
            backgroundImage: `radial-gradient(circle at 1px 1px, rgba(255, 255, 255, 0.15) 1px, transparent 0)`,
            backgroundSize: '20px 20px'
          }}
        />

        <div className="relative container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <div className="inline-flex items-center justify-center gap-2 px-4 py-2 rounded-full bg-white/10 backdrop-blur-sm text-white mb-6">
              <Shield className="w-4 h-4" />
              <span className="text-sm font-medium">Your Privacy Matters</span>
            </div>
            <h1 className="text-4xl md:text-5xl font-gilmer font-bold mb-6 text-white">
              Privacy Policy
            </h1>
            <p className="text-xl text-white/80 mb-8">
              Learn how we protect your data and respect your privacy
            </p>
          </div>
        </div>
      </section>

      {/* Policy Content Section */}
      <section className="py-20 bg-gradient-to-b from-gray-50 to-white relative">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            {/* Content Card */}
            <div className="bg-white rounded-2xl shadow-xl border border-gray-100 overflow-hidden">
              <AdBlockerHelp />
              {/* Loading State */}
              {!isLoaded && !error && (
                <div className="p-8 flex flex-col items-center justify-center min-h-[400px]">
                  <div className="animate-pulse space-y-6 w-full">
                    <div className="h-4 bg-gray-200 rounded w-3/4 mx-auto"></div>
                    <div className="space-y-3">
                      <div className="h-3 bg-gray-200 rounded"></div>
                      <div className="h-3 bg-gray-200 rounded w-5/6"></div>
                      <div className="h-3 bg-gray-200 rounded w-4/6"></div>
                    </div>
                    <div className="space-y-3">
                      <div className="h-3 bg-gray-200 rounded"></div>
                      <div className="h-3 bg-gray-200 rounded w-5/6"></div>
                      <div className="h-3 bg-gray-200 rounded w-4/6"></div>
                    </div>
                  </div>
                  <div className="mt-8 flex items-center gap-2 text-gray-500">
                    <Lock className="w-5 h-5 animate-pulse" />
                    <span>Loading Privacy Policy...</span>
                  </div>
                </div>
              )}

              {/* Termly Embed */}
              <div
                className="termly-embed-container"
                style={{
                  height: 'calc(100vh - 300px)',
                  minHeight: '600px',
                  maxHeight: '800px',
                  overflow: 'auto'
                }}
              >
                <div
                  name="termly-embed"
                  data-id={policyId}
                  data-type="iframe"
                ></div>
              </div>
            </div>

            {/* Additional Info */}
            <div className="mt-8 text-center text-gray-500 text-sm">
              <p>Last updated: {new Date().toLocaleDateString()}</p>
              <p>If you have any questions about our privacy policy, please <a href="/contact" className="text-blue-600 hover:text-blue-500 underline">contact us</a>.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PrivacyPolicy;