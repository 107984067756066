import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Camera, Share, Shield, ChevronRight, Sparkles, BookOpen, Heart } from 'lucide-react';

const HowItWorksSection = () => {
  const [deviceType, setDeviceType] = useState('desktop');

  const getMobileOperatingSystem = () => {
    const isDevMode = process.env.NODE_ENV === 'development';
    if (isDevMode) {
      const urlParams = new URLSearchParams(window.location.search);
      const deviceParam = urlParams.get('device');
      if (deviceParam) {
        return deviceParam;
      }
    }

    const userAgent = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(userAgent)) {
      return 'ios';
    } else if (/android/.test(userAgent)) {
      return 'android';
    }
    return 'desktop';
  };

  useEffect(() => {
    setDeviceType(getMobileOperatingSystem());
  }, []);

  const steps = [
    {
      step: "1",
      title: "Capture Your Heritage",
      description: "Transform physical memories into digital treasures. Future updates will include AI recognition to help identify items and help focus on what matters - your story.",
      icon: Camera,
      features: [
        "Property and category organization",
        "High-quality photo capture",
        "AI-powered item recognition [COMING SOON]"
      ],
      color: "from-blue-500 to-blue-600",
      titleGradient: "from-blue-600 via-blue-500 to-blue-600"
    },
    {
      step: "2",
      title: "Tell Your Story",
      description: "Add rich context to each item - from family history to precious memories. Build a living digital archive.",
      icon: BookOpen,
      features: [
        "Text rich stories",
        "Audio recording storage",
        "Story sharing on social media [COMING SOON]"
      ],
      color: "from-purple-500 to-pink-500",
      titleGradient: "from-purple-600 via-pink-500 to-purple-600"
    },
    {
      step: "3",
      title: "Share Securely",
      description: "Choose what to share and with whom. Keep your family's legacy alive across generations.",
      icon: Heart,
      features: [
        "Granular privacy controls",
        "Family member invites",
        "Collaborative storytelling [COMING SOON]"
      ],
      color: "from-blue-500 to-purple-500",
      titleGradient: "from-blue-600 via-purple-500 to-blue-600"
    }
  ];

  return (
    <section className="py-16 relative overflow-hidden">
      {/* Previous background elements remain the same */}
      <div className="absolute inset-0 bg-gradient-to-b from-gray-50 to-white" />
      <div className="absolute inset-0 opacity-[0.15]"
        style={{
          backgroundImage: `radial-gradient(circle at 1px 1px, #6366F1 1px, transparent 0)`,
          backgroundSize: '48px 48px'
        }}
      />
      
      <div className="container mx-auto px-4 relative">
        {/* Header remains the same */}
        <div className="text-center mb-20">
          <div className="inline-flex items-center justify-center gap-2 px-4 py-2 rounded-full bg-blue-50 text-blue-600 mb-6">
            <Sparkles className="w-4 h-4" />
            <span className="text-sm font-medium">Simple Yet Powerful</span>
          </div>
          <h2 className="text-4xl md:text-5xl font-gilmer font-bold mb-6 bg-gradient-to-r from-blue-700 via-purple-600 to-blue-700 bg-clip-text text-transparent">
            Your Journey Starts Here
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Three simple steps to preserve your family's legacy for generations to come
          </p>
        </div>

        {/* Steps */}
        <div className="grid md:grid-cols-3 gap-8 relative">
          {/* Connecting Lines */}
          <div className="hidden md:block absolute top-1/2 left-1/4 right-1/4 h-0.5 bg-gradient-to-r from-blue-200 via-purple-200 to-blue-200" />
          
          {steps.map((step, index) => (
            <div key={index} className="group relative">
              <div className="relative bg-white rounded-2xl p-8 shadow-lg transition-all duration-500 hover:shadow-2xl hover:-translate-y-2 h-[440px] flex flex-col">
                {/* Step Number */}
                <div className="absolute -top-4 left-8 bg-white rounded-full w-8 h-8 flex items-center justify-center shadow-md">
                  <span className="font-gilmer font-bold text-gray-800">{step.step}</span>
                </div>

                {/* Top Content Section */}
                <div className="space-y-4">
                  {/* Icon */}
                  <div className={`p-4 rounded-xl bg-gradient-to-br ${step.color} group-hover:scale-110 transition-transform duration-300`}>
                    <step.icon className="w-8 h-8 text-white" />
                  </div>

                  {/* Enhanced Title and Description */}
                  <h3 className={`text-2xl font-gilmer font-bold bg-gradient-to-r ${step.titleGradient} bg-clip-text text-transparent 
                                filter drop-shadow-sm transform transition-transform duration-300 group-hover:scale-[1.02]`}>
                    {step.title}
                  </h3>
                  <p className="text-gray-600">
                    {step.description}
                  </p>
                </div>

                {/* Features List */}
                <ul className="space-y-3 mt-6">
                  {step.features.map((feature, i) => (
                    <li key={i} className="flex items-center gap-2 text-sm text-gray-600">
                      <ChevronRight className="w-4 h-4 text-blue-500 flex-shrink-0" />
                      <span>
                        {feature.includes('[COMING SOON]') ? (
                          <>
                            {feature.replace(' [COMING SOON]', '')}
                            <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gradient-to-r from-blue-500/90 to-purple-500/90 text-white">
                              COMING SOON
                            </span>
                          </>
                        ) : feature}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        {/* CTA remains the same */}
        <div className="mt-16 text-center">
          {deviceType === 'desktop' ? (
            <Link
              to="/download"
              className="inline-flex items-center gap-2 px-8 py-4 rounded-full bg-gradient-to-r from-blue-600 to-purple-600 text-white font-medium transition-all duration-300 hover:shadow-lg hover:shadow-blue-500/25 hover:-translate-y-1"
            >
              Get Started Now
              <ChevronRight className="w-4 h-4" />
            </Link>
          ) : (
            <a
              href={deviceType === 'ios' 
                ? "https://apps.apple.com/us/app/treasure-tracker-app/id6470204759"
                : "https://play.google.com/store/apps/details?id=com.treasure.tracker.production&hl=en"
              }
              className="inline-flex items-center gap-2 px-8 py-4 rounded-full bg-gradient-to-r from-blue-600 to-purple-600 text-white font-medium transition-all duration-300 hover:shadow-lg hover:shadow-blue-500/25 hover:-translate-y-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download for {deviceType === 'ios' ? 'iOS' : 'Android'}
              <ChevronRight className="w-4 h-4" />
            </a>
          )}
        </div>
      </div>
    </section>
  );
};

export default HowItWorksSection;