import React, { useEffect, useState } from 'react';

const quotes = [
  "Every family has a story, every treasure holds a chapter.",
  "Treasures are the bookmarks in our family's story.",
  "The greatest treasures we have are the memories we keep.",
  "Legacy isn't left, it's built—one treasure at a time."
];

const LoadingScreen = ({ isLoading }) => {
  const [progress, setProgress] = useState(0);
  const [animationState, setAnimationState] = useState('spinning');
  const [currentQuote, setCurrentQuote] = useState('');
  const [nextQuote, setNextQuote] = useState('');
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [shouldExit, setShouldExit] = useState(false);

  useEffect(() => {
    const selectedQuotes = [...quotes]
      .sort(() => Math.random() - 0.5)
      .slice(0, 2);
    
    setCurrentQuote(selectedQuotes[0]);
    setNextQuote(selectedQuotes[1]);

    const quoteTimer = setTimeout(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentQuote(selectedQuotes[1]);
        setIsTransitioning(false);
      }, 500); // Half of the transition duration
    }, 2500);

    return () => clearTimeout(quoteTimer);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setProgress(100);
      setAnimationState('complete');
      
      const exitDelay = setTimeout(() => {
        setShouldExit(true);
      }, 1000);
      
      return () => clearTimeout(exitDelay);
    }

    const interval = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(interval);
          setAnimationState('complete');
          return 100;
        }
        return Math.min(prev + 1, 100);
      });
    }, 30);

    return () => clearInterval(interval);
  }, [isLoading]);

  return (
    <div 
      className={`fixed inset-0 bg-white z-50 flex flex-col items-center justify-center transition-opacity duration-500 ${
        shouldExit && !isLoading ? 'opacity-0 pointer-events-none' : 'opacity-100'
      }`}
    >
      <div className="h-[600px] flex flex-col items-center justify-center">
        <div className="relative">
          <div 
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96 h-96 bg-primary-sky/30 rounded-full blur-xl animate-pulse"
            style={{
              animation: 'pulse-glow 2s infinite'
            }}
          />
          <img 
            src="/logo.png"
            alt="Treasure Tracker"
            className="relative h-80 w-80 object-contain"
            style={{
              transform: animationState === 'spinning' 
                ? `rotate(${progress * 3.6}deg)` 
                : 'rotate(0deg)',
              animation: animationState === 'complete' 
                ? 'pulse 0.5s ease-in-out' 
                : undefined,
              transition: 'transform 0.5s linear'
            }}
          />
        </div>

        <div className="mt-12 relative w-80">
          <div className="absolute -top-6 left-1/2 transform -translate-x-1/2 font-gilmer text-primary-navy text-lg">
            {progress}%
          </div>
          <div className="h-3 bg-gradient-to-r from-primary-pink/10 via-primary-purple/10 to-primary-sky/10 rounded-full overflow-hidden backdrop-blur-sm shadow-inner">
            <div 
              className="h-full relative"
              style={{ width: `${progress}%` }}
            >
              <div 
                className="absolute inset-0 bg-gradient-to-r from-primary-pink via-primary-purple to-primary-sky"
                style={{
                  animation: 'shimmer 2s linear infinite',
                }}
              />
              <div 
                className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent opacity-30"
                style={{
                  animation: 'slide 1.5s linear infinite',
                  backgroundSize: '200% 100%',
                }}
              />
            </div>
          </div>
        </div>

        {/* Fixed height quote container with smooth transitions */}
        <div className="mt-12 h-32 w-full max-w-md flex items-center justify-center overflow-hidden">
          <div 
            className="relative px-8 py-6 w-full h-full flex items-center justify-center"
            style={{
              background: 'linear-gradient(120deg, rgba(255,255,255,0.8), rgba(255,255,255,0.2))',
              boxShadow: '0 8px 32px rgba(0,0,0,0.05)',
              backdropFilter: 'blur(10px)',
              transform: 'perspective(1000px) rotateX(5deg)',
              animation: 'float 3s ease-in-out infinite'
            }}
          >
            <div 
              className={`absolute inset-0 flex items-center justify-center px-8 transition-opacity duration-1000 ${
                isTransitioning ? 'opacity-0' : 'opacity-100'
              }`}
            >
              <p className="font-gilmer text-primary-navy/80 italic text-center">
                <span className="text-primary-purple text-3xl font-serif absolute -left-4 top-0">"</span>
                {currentQuote}
                <span className="text-primary-sky text-3xl font-serif absolute -right-4 bottom-0">"</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;