import React, { useState, useEffect } from 'react';
import { Shield, Lock, CloudOff, Users, Key, Database } from 'lucide-react';

export default function SecuritySection() {
  const [activeFeature, setActiveFeature] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  
  useEffect(() => {
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      const { innerWidth, innerHeight } = window;
      setMousePosition({
        x: (clientX / innerWidth) * 100,
        y: (clientY / innerHeight) * 100,
      });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const features = [
    {
      icon: Key,
      title: "End-to-End Encryption",
      description: "Your data is encrypted at all times.",
      details: "We use military-grade AES-256 encryption to protect your data both in transit and at rest.",
      color: "text-blue-500",
      gradient: "from-blue-500/20 to-blue-600/20"
    },
    {
      icon: Database,
      title: "Secure Cloud Storage",
      description: "Protected backups of all your information.",
      details: "Redundant storage across multiple secure facilities ensures your data is always safe and available.",
      color: "text-purple-500",
      gradient: "from-purple-500/20 to-purple-600/20"
    },
    {
      icon: Users,
      title: "Privacy Controls",
      description: "You control who sees what.",
      details: "Granular sharing permissions let you decide exactly who can access each item in your collection.",
      color: "text-indigo-500",
      gradient: "from-indigo-500/20 to-indigo-600/20"
    }
  ];

  return (
    <section className="py-16 relative overflow-hidden">
      {/* Dynamic Background */}
      <div className="absolute inset-0 bg-gray-50">
        {/* Gradient Orbs */}
        <div 
          className="absolute inset-0 opacity-30"
          style={{
            background: `
              radial-gradient(600px circle at ${mousePosition.x}% ${mousePosition.y}%, rgba(99, 102, 241, 0.1), transparent 40%),
              radial-gradient(800px circle at ${100 - mousePosition.x}% ${100 - mousePosition.y}%, rgba(147, 51, 234, 0.1), transparent 40%)
            `
          }}
        />
        
        {/* Dot Pattern */}
        <div 
          className="absolute inset-0 opacity-[0.15]"
          style={{
            backgroundImage: `radial-gradient(circle at 1px 1px, #6366F1 1px, transparent 0)`,
            backgroundSize: '48px 48px',
            transform: `translate(${(mousePosition.x - 50) / 20}px, ${(mousePosition.y - 50) / 20}px)`
          }}
        />
      </div>

      <div className="container mx-auto px-4 relative">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <div className="inline-flex items-center justify-center gap-2 px-4 py-2 rounded-full bg-blue-50 text-blue-600 mb-6">
            <Shield className="w-4 h-4" />
            <span className="text-sm font-medium">Enterprise-Grade Security</span>
          </div>
          
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600 bg-clip-text text-transparent">
            Your Security is Our Priority
          </h2>
          <p className="text-gray-600 text-lg">
            We use industry-leading encryption and security measures to keep your family's treasures safe.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="group relative"
              onMouseEnter={() => setActiveFeature(index)}
              onMouseLeave={() => setActiveFeature(null)}
            >
              <div className={`p-8 rounded-2xl bg-white/80 backdrop-blur-sm border border-gray-100 transition-all duration-300 
                            ${activeFeature === index ? 'shadow-xl -translate-y-2' : 'shadow-md hover:shadow-lg'}`}>
                <div className="mb-6">
                  <div className={`w-14 h-14 rounded-xl bg-gradient-to-br ${feature.gradient} flex items-center justify-center 
                                ${activeFeature === index ? 'scale-110' : ''} transition-transform duration-300`}>
                    <feature.icon className={`w-6 h-6 ${feature.color}`} />
                  </div>
                </div>
                
                <h3 className={`text-xl font-bold mb-3 ${feature.color}`}>
                  {feature.title}
                </h3>
                
                <p className="text-gray-600 mb-4">
                  {feature.description}
                </p>
                
                <div className={`overflow-hidden transition-all duration-300 
                              ${activeFeature === index ? 'max-h-24 opacity-100' : 'max-h-0 opacity-0'}`}>
                  <p className="text-sm text-gray-500">
                    {feature.details}
                  </p>
                </div>
              </div>

              {/* Animated background gradient */}
              <div className={`absolute inset-0 bg-gradient-to-r ${feature.gradient} 
                            rounded-2xl blur-xl transition-opacity duration-300 -z-10
                            ${activeFeature === index ? 'opacity-100' : 'opacity-0'}`} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}