import React, { useState, useEffect } from 'react';
import { Menu, X } from 'lucide-react';
import { NavLink, useLocation, Link } from 'react-router-dom';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLogoHovered, setIsLogoHovered] = useState(false);
  const [deviceType, setDeviceType] = useState('desktop');
  const location = useLocation();

  const getMobileOperatingSystem = () => {
    const isDevMode = process.env.NODE_ENV === 'development';
    if (isDevMode) {
      const urlParams = new URLSearchParams(window.location.search);
      const deviceParam = urlParams.get('device');
      if (deviceParam) {
        return deviceParam;
      }
    }

    const userAgent = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(userAgent)) {
      return 'ios';
    } else if (/android/.test(userAgent)) {
      return 'android';
    }
    return 'desktop';
  };

  useEffect(() => {
    setDeviceType(getMobileOperatingSystem());
  }, []);

  const navigationItems = [
    { name: 'Home', href: '/' },
    { name: 'Product', href: '/product' },
    { name: 'About', href: '/about' },
    { name: 'Corporate Partnerships', href: '/partnerships' },
    { name: 'Pricing', href: '/pricing' },
    { name: 'Blog', href: '/blog' },
    { name: 'Contact', href: '/contact' }
  ];

  const handleNavClick = () => {
    if (window.innerWidth < 768) {
      setIsMenuOpen(false);
    }
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleKeyPress = (event, action) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      action();
    }
  };

  const DownloadButton = ({ className, isMobile = false }) => {
    const baseClassName = `${className} ${isMobile ? 'text-center justify-center w-full' : ''}`;

    if (deviceType === 'desktop') {
      return (
        <Link
          to="/download"
          className={baseClassName}
          aria-label="Download Application"
        >
          Download App
        </Link>
      );
    }

    return (
      <a
        href={deviceType === 'ios' 
          ? "https://apps.apple.com/us/app/treasure-tracker-app/id6470204759"
          : "https://play.google.com/store/apps/details?id=com.treasure.tracker.production&hl=en"
        }
        className={baseClassName}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`Download for ${deviceType === 'ios' ? 'iOS' : 'Android'}`}
      >
        Download for {deviceType === 'ios' ? 'iOS' : 'Android'}
      </a>
    );
  };

  return (
    <header className="fixed w-full bg-gray-900 z-50 border-b border-primary-pink/20" role="banner">
      <nav className="container mx-auto px-4 py-4" role="navigation" aria-label="Main navigation">
        <div className="flex items-center justify-between">
          <NavLink 
            to="/" 
            className="flex items-center space-x-2 relative transform transition-all duration-700 ease-out md:hover:scale-125"
            onMouseEnter={() => window.innerWidth >= 768 && setIsLogoHovered(true)}
            onMouseLeave={() => window.innerWidth >= 768 && setIsLogoHovered(false)}
            onClick={handleNavClick}
            aria-label="Treasure Tracker Home"
          >
            <div className="relative w-40 h-12">
              <img 
                src="/images/header.png" 
                alt="Treasure Tracker Logo"
                className={`absolute w-full h-full object-contain transition-opacity duration-700 ease-out
                  ${isLogoHovered ? 'opacity-0' : 'opacity-100'}`}
              />
              <img 
                src="/images/header-active.png"
                alt="Treasure Tracker Logo Alternate"
                className={`absolute w-full h-full object-contain transition-opacity duration-700 ease-out
                  ${isLogoHovered ? 'opacity-100' : 'opacity-0'} md:block hidden`}
                aria-hidden="true"
              />
            </div>
          </NavLink>

          <div className="hidden md:flex items-center space-x-8" role="menubar">
            {navigationItems.map((item) => (
              <NavLink
                key={item.name}
                to={item.href}
                role="menuitem"
                aria-current={location.pathname === item.href ? 'page' : undefined}
                className={({ isActive }) => `
                  font-gilmer relative py-2 text-white/90 hover:text-white
                  before:content-[''] before:absolute before:bottom-0 before:left-0 
                  before:w-full before:h-0.5 before:bg-primary-purple
                  before:transform before:scale-x-0 before:origin-right
                  before:transition-transform before:duration-300
                  hover:before:scale-x-100 hover:before:origin-left
                  focus:outline-none focus:ring-offset-2
                  ${isActive ? 'text-primary-purple before:scale-x-100' : ''}
                  ${location.pathname === item.href ? 'text-primary-purple before:scale-x-100' : ''}
                `}
              >
                {item.name}
              </NavLink>
            ))}
            <DownloadButton 
              className="bg-primary-purple text-white px-6 py-2 rounded-full hover:bg-primary-sky transition-all duration-300 hover:scale-105 font-gilmer
                focus:outline-none focus:ring-2 focus:ring-primary-purple focus:ring-offset-2 focus:ring-offset-gray-900"
            />
          </div>

          <button
            className="md:hidden text-white p-2 rounded-lg hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-primary-purple focus:ring-offset-2 focus:ring-offset-gray-900"
            onClick={handleMenuToggle}
            onKeyPress={(e) => handleKeyPress(e, handleMenuToggle)}
            aria-expanded={isMenuOpen}
            aria-controls="mobile-menu"
            aria-label={isMenuOpen ? "Close main menu" : "Open main menu"}
          >
            {isMenuOpen ? <X aria-hidden="true" /> : <Menu aria-hidden="true" />}
          </button>
        </div>

        {isMenuOpen && (
          <div 
            id="mobile-menu"
            className="md:hidden pt-4 pb-6"
            role="menu"
            aria-label="Mobile navigation menu"
          >
            <div className="flex flex-col space-y-4">
              {navigationItems.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  role="menuitem"
                  onClick={handleNavClick}
                  aria-current={location.pathname === item.href ? 'page' : undefined}
                  className={({ isActive }) => `
                    font-gilmer relative py-2 text-white/90 hover:text-white
                    before:content-[''] before:absolute before:bottom-0 before:left-0 
                    before:w-full before:h-0.5 before:bg-primary-purple
                    before:transform before:scale-x-0 before:origin-right
                    before:transition-transform before:duration-300
                    hover:before:scale-x-100 hover:before:origin-left
                    focus:outline-none focus:ring-2 focus:ring-primary-purple focus:ring-offset-2 focus:ring-offset-gray-900
                    ${isActive ? 'text-primary-purple before:scale-x-100' : ''}
                    ${location.pathname === item.href ? 'text-primary-purple before:scale-x-100' : ''}
                  `}
                >
                  {item.name}
                </NavLink>
              ))}
              <DownloadButton 
                className="bg-primary-purple text-white px-6 py-2 rounded-full hover:bg-primary-sky transition-all duration-300 hover:scale-105 font-gilmer
                  focus:outline-none focus:ring-2 focus:ring-primary-purple focus:ring-offset-2 focus:ring-offset-gray-900"
                isMobile={true}
              />
            </div>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;