import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Building2, Scale, Home, Users, BadgePercent, Package, Sparkles, ChevronRight, Loader2, Check, XCircle, Send } from 'lucide-react';
import { Card, CardContent } from '../components/ui/Card';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "../components/ui/Dialog";
import SEOHead from '../components/SEOHead';

// Separate WaitlistModal component
const WaitlistModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    phone: ''
  });
  const [submitStatus, setSubmitStatus] = useState('idle');
  const [error, setError] = useState('');
  const [isShaking, setIsShaking] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus('loading');
    setError('');

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/corporate-waitlist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName: formData.firstName,
          lastName: formData.lastName,
          companyName: formData.companyName,
          email: formData.email,
          phoneNumber: formData.phone
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to join waitlist');
      }

      setSubmitStatus('success');

      // Reset form and close modal after delay
      setTimeout(() => {
        onClose();
        setFormData({
          firstName: '',
          lastName: '',
          companyName: '',
          email: '',
          phone: ''
        });
        setSubmitStatus('idle');
      }, 3000);

    } catch (err) {
      console.error('Waitlist submission error:', err);
      setError(err.message || 'Unable to join waitlist. Please try again.');
      setSubmitStatus('error');
      setIsShaking(true);

      setTimeout(() => {
        setIsShaking(false);
      }, 820);

      setTimeout(() => {
        setSubmitStatus('idle');
        setError('');
      }, 3000);
    }
  };

  const inputClasses = "w-full px-4 py-3 rounded-lg bg-white/90 border border-white/20 focus:outline-none focus:ring-2 focus:ring-white/50 focus:border-transparent transition-all text-gray-800 placeholder:text-gray-500";

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[500px] bg-gradient-to-br from-blue-600 to-purple-600 border-white/20">
        <DialogHeader className="space-y-3">
          <DialogTitle className="text-2xl font-bold text-white">
            Join the Waitlist
          </DialogTitle>
          <DialogDescription className="text-white/80 text-lg">
            Be among the first to know when our partnership program launches.
          </DialogDescription>
        </DialogHeader>

        <form onSubmit={handleSubmit} className={`space-y-6 mt-4 ${isShaking ? 'animate-shake' : ''}`}>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-white mb-1">
                First Name
              </label>
              <input
                type="text"
                required
                value={formData.firstName}
                onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                className={inputClasses}
                placeholder="John"
                disabled={submitStatus === 'loading' || submitStatus === 'success'}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-white mb-1">
                Last Name
              </label>
              <input
                type="text"
                required
                value={formData.lastName}
                onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                className={inputClasses}
                placeholder="Doe"
                disabled={submitStatus === 'loading' || submitStatus === 'success'}
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-white mb-1">
              Company Name
            </label>
            <input
              type="text"
              required
              value={formData.companyName}
              onChange={(e) => setFormData({ ...formData, companyName: e.target.value })}
              className={inputClasses}
              placeholder="Acme Inc."
              disabled={submitStatus === 'loading' || submitStatus === 'success'}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-white mb-1">
              Email Address
            </label>
            <input
              type="email"
              required
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              className={inputClasses}
              placeholder="john@example.com"
              disabled={submitStatus === 'loading' || submitStatus === 'success'}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-white mb-1">
              Phone Number
            </label>
            <input
              type="tel"
              required
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
              className={inputClasses}
              placeholder="(555) 555-5555"
              disabled={submitStatus === 'loading' || submitStatus === 'success'}
            />
          </div>

          {error && (
            <div className="text-red-200 text-sm bg-red-500/10 p-3 rounded-lg text-center">
              {error}
            </div>
          )}

          <button
            type="submit"
            disabled={submitStatus !== 'idle'}
            className={`w-full px-6 py-3 rounded-lg transition-all duration-300 
              flex items-center justify-center gap-2 group
              ${submitStatus === 'loading'
                ? 'bg-white text-blue-500'
                : submitStatus === 'success'
                  ? 'bg-green-500 text-white'
                  : submitStatus === 'error'
                    ? 'bg-red-500 text-white hover:bg-red-600'
                    : 'bg-white text-blue-600 hover:bg-white/90 hover:shadow-lg transform hover:-translate-y-0.5'
              } disabled:opacity-50 disabled:cursor-not-allowed`}
          >
            {submitStatus === 'loading' ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Submitting...</span>
              </>
            ) : submitStatus === 'success' ? (
              <>
                <Check className="h-4 w-4" />
                <span>Successfully Submitted!</span>
              </>
            ) : submitStatus === 'error' ? (
              <>
                <XCircle className="h-4 w-4" />
                <span>Please Try Again</span>
              </>
            ) : (
              <>
                <span className="group-hover:scale-105 transition-transform">Join Waitlist</span>
                <Send className="h-4 w-4 group-hover:translate-x-1 transition-transform duration-300" />
              </>
            )}
          </button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const CorporatePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="flex flex-col min-h-screen relative">
      <SEOHead
        title="Partner Program - Treasure Tracker for Businesses"
        description="Join Treasure Tracker's partner program. Help your clients preserve their treasured possessions while adding value to your services."
        path="/corporate"
      />
      {/* Hero Section */}
      <section className="relative pt-32 pb-20">
        {/* Background gradient */}
        <div
          className="absolute inset-0 bg-gradient-to-br from-blue-500/90 to-purple-500/90 backdrop-blur-sm"
          aria-hidden="true"
        />

        {/* Dot pattern overlay */}
        <div
          className="absolute inset-0 opacity-10"
          style={{
            backgroundImage: `radial-gradient(circle at 1px 1px, rgba(255, 255, 255, 0.15) 1px, transparent 0)`,
            backgroundSize: '20px 20px'
          }}
          aria-hidden="true"
        />

        <div className="relative container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            {/* Refined Coming Soon Badge */}
            <div className="inline-flex items-center justify-center gap-2 px-6 py-3 rounded-full 
                          bg-white/10 backdrop-blur-sm text-white mb-8
                          border border-white/20 shadow-[0_0_20px_rgba(255,255,255,0.2)]">
              <div className="animate-[bounce_2s_ease-in-out_infinite]">
                <Sparkles className="w-5 h-5" />
              </div>
              <span className="text-base font-medium tracking-wide">Coming Soon - Partner Program</span>
              <div className="animate-[bounce_2s_ease-in-out_infinite] delay-100">
                <Sparkles className="w-5 h-5" />
              </div>
            </div>

            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6 bg-gradient-to-r from-white via-white/90 to-white bg-clip-text text-transparent">
              Strengthen Client Relationships
            </h1>
            <p className="text-xl text-white/80 mb-8">
              Help your clients preserve their treasured possessions while adding value to your services through our partnership program.
            </p>
            <button
              onClick={openModal}
              className="inline-flex items-center justify-center gap-2 group
           bg-white text-blue-600 hover:bg-white/90 px-8 py-3 rounded-full font-medium 
           transition-all duration-200 hover:shadow-[0_0_20px_rgba(255,255,255,0.3)] 
           hover:scale-105"
            >
              <span className="group-hover:scale-105 transition-transform">Join Waitlist</span>
              <Send className="h-4 w-4 group-hover:translate-x-1 transition-transform duration-300" />
            </button>
          </div>
        </div>
      </section>

      {/* Partnership Types */}
      <section className="py-24 bg-gradient-to-b from-gray-50 to-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-16 bg-gradient-to-r from-blue-700 via-purple-600 to-blue-700 bg-clip-text text-transparent">
            Who We Partner With
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                icon: <Scale className="h-12 w-12 text-blue-600" />,
                title: "Insurance Providers",
                description: "Offer your clients a powerful tool for documenting assets and streamlining claims."
              },
              {
                icon: <Building2 className="h-12 w-12 text-blue-600" />,
                title: "Estate Attorneys",
                description: "Provide comprehensive asset documentation to simplify estate planning."
              },
              {
                icon: <Home className="h-12 w-12 text-blue-600" />,
                title: "Real Estate Agents",
                description: "Give your clients a premium tool for documenting their new home and valuables."
              },
              {
                icon: <Users className="h-12 w-12 text-blue-600" />,
                title: "Retirement Communities",
                description: "Help residents document and share their life stories and precious memories."
              }
            ].map((partner, index) => (
              <Card key={index} className="group hover:shadow-xl transition-all duration-300">
                <CardContent className="p-6 text-center">
                  <div className="mb-4 flex justify-center">
                    <div className="p-3 rounded-lg bg-blue-50 group-hover:scale-110 transition-transform duration-300">
                      {partner.icon}
                    </div>
                  </div>
                  <h3 className="text-xl font-bold text-gray-900 mb-2">
                    {partner.title}
                  </h3>
                  <p className="text-gray-600">
                    {partner.description}
                  </p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Partnership Options */}
      <section className="py-24 bg-gradient-to-br from-blue-500 to-purple-500">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-white text-center mb-16">
            Partnership Options
          </h2>
          <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
            <Card className="bg-white/10 backdrop-blur-sm border-white/20">
              <CardContent className="p-8">
                <div className="mb-6">
                  <BadgePercent className="h-12 w-12 text-white mb-4" />
                  <h3 className="text-2xl font-bold text-white mb-2">Referral Program</h3>
                  <p className="text-white/80">
                    Refer your clients to Treasure Tracker and they'll receive exclusive discounts. Your brand appears as their trusted sponsor within the app.
                  </p>
                </div>
                <ul className="space-y-3 text-white/80">
                  <li className="flex items-center gap-2">
                    <ChevronRight className="w-4 h-4 text-white" />
                    <span>Special client discount codes</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <ChevronRight className="w-4 h-4 text-white" />
                    <span>In-app sponsorship recognition</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <ChevronRight className="w-4 h-4 text-white" />
                    <span>No upfront costs</span>
                  </li>
                </ul>
              </CardContent>
            </Card>

            <Card className="bg-white/10 backdrop-blur-sm border-white/20">
              <CardContent className="p-8">
                <div className="mb-6">
                  <Package className="h-12 w-12 text-white mb-4" />
                  <h3 className="text-2xl font-bold text-white mb-2">Bulk Subscription</h3>
                  <p className="text-white/80">
                    Purchase subscriptions in bulk to offer Treasure Tracker as a value-added service to your clients.
                  </p>
                </div>
                <ul className="space-y-3 text-white/80">
                  <li className="flex items-center gap-2">
                    <ChevronRight className="w-4 h-4 text-white" />
                    <span>Volume pricing discounts</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <ChevronRight className="w-4 h-4 text-white" />
                    <span>Branded client experience</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <ChevronRight className="w-4 h-4 text-white" />
                    <span>Dedicated support team</span>
                  </li>
                </ul>
              </CardContent>
            </Card>
          </div>
        </div>
      </section>

      {/* Getting Started */}
      <section className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16 bg-gradient-to-r from-blue-700 via-purple-600 to-blue-700 bg-clip-text text-transparent">
            Getting Started
          </h2>
          <div className="max-w-4xl mx-auto">
            <div className="relative">
              {/* Vertical Line */}
              <div
                className="absolute left-8 top-0 bottom-0 w-0.5 bg-gradient-to-b from-blue-500 to-purple-500 
                     hidden md:block"
                style={{
                  transform: 'translateX(-50%)',
                  zIndex: 0
                }}
              />

              {/* Steps */}
              <div className="space-y-12">
                {[
                  {
                    number: "1",
                    title: "Join the Waitlist",
                    description: "Sign up to be notified when our partner program launches."
                  },
                  {
                    number: "2",
                    title: "Choose Your Partnership Type",
                    description: "Select between our referral program or bulk subscription options."
                  },
                  {
                    number: "3",
                    title: "Onboarding & Setup",
                    description: "We'll help you set up your branded experience and provide all necessary marketing materials."
                  },
                  {
                    number: "4",
                    title: "Launch & Grow",
                    description: "Start offering Treasure Tracker to your clients and strengthen your relationships."
                  }
                ].map((step, index) => (
                  <div
                    key={index}
                    className="relative flex items-start gap-8 group"
                  >
                    {/* Step Number with Animation */}
                    <div className="relative flex-shrink-0 z-10">
                      <div className="w-16 h-16 rounded-full bg-gradient-to-br from-blue-500 to-purple-500 
                              flex items-center justify-center text-white text-xl font-bold
                              transition-transform duration-300 group-hover:scale-110
                              shadow-lg shadow-blue-500/20">
                        {step.number}
                      </div>
                    </div>

                    {/* Content */}
                    <div className="flex-1 pt-2">
                      <h3 className="text-2xl font-bold text-gray-900 mb-2 
                             transition-colors duration-300 group-hover:text-blue-600">
                        {step.title}
                      </h3>
                      <p className="text-lg text-gray-600">
                        {step.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-24 bg-gradient-to-br from-blue-500 to-purple-500">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-white mb-6">
            Ready to Join Our Partner Network?
          </h2>
          <p className="text-xl text-white/80 mb-8 max-w-2xl mx-auto">
            Be among the first to offer Treasure Tracker to your clients when our partnership program launches.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <button
              onClick={openModal}
              className="bg-white text-blue-600 hover:bg-white/90 px-8 py-3 rounded-full font-medium 
                 transition-all duration-200 hover:shadow-[0_0_20px_rgba(255,255,255,0.3)] 
                 hover:scale-105"
            >
              Join the Waitlist
            </button>
            <Link
              to="/product"
              className="bg-white/10 text-white hover:bg-white/20 px-8 py-3 rounded-full font-medium 
                 transition-all duration-200"
            >
              Learn More
            </Link>
          </div>
        </div>
      </section>
      <WaitlistModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default CorporatePage;