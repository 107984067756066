import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { User, Clock, ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import {
  FaSquareFacebook,
  FaLinkedin,
  FaXTwitter
} from "react-icons/fa6";
import SEOHead from '../components/SEOHead';

// Rich text renderer helper
const RichText = ({ content }) => {
  const headerClasses = "font-gilmer font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent";

  if (Array.isArray(content)) {
    return content.map((block, index) => {
      switch (block.type) {
        case 'paragraph':
          return <p key={index} className="text-gray-600 leading-relaxed mb-6">{block.children[0].text}</p>;
        case 'heading':
          const HeadingTag = `h${block.level}`;
          return (
            <HeadingTag
              key={index}
              className={`${headerClasses}
                ${block.level === 1 ? 'text-4xl mb-8' :
                  block.level === 2 ? 'text-3xl mb-6 mt-12' :
                    'text-2xl mb-4 mt-8'}`}
            >
              {block.children[0].text}
            </HeadingTag>
          );
        case 'list':
          const ListTag = block.format === 'ordered' ? 'ol' : 'ul';
          return (
            <ListTag
              key={index}
              className={`pl-6 mb-6 ${block.format === 'ordered' ? 'list-decimal' : 'list-disc'}`}
            >
              {block.children.map((item, itemIndex) => (
                <li key={itemIndex} className="text-gray-600 mb-2">
                  {item.children[0].text}
                </li>
              ))}
            </ListTag>
          );
        case 'link':
          return (
            <a
              key={index}
              href={block.url}
              className="text-blue-600 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              {block.children[0].text}
            </a>
          );
        case 'quote':
          return (
            <blockquote
              key={index}
              className="border-l-4 border-blue-500 pl-4 italic my-6"
            >
              {block.children[0].text}
            </blockquote>
          );
        default:
          return null;
      }
    });
  }

  // For string content, wrap headers in gradient styling
  const content_with_branded_headers = content.replace(
    /<h([1-6])>(.*?)<\/h[1-6]>/g,
    `<h$1 class="${headerClasses}">$2</h$1>`
  );

  return <div className="text-gray-600 leading-relaxed" dangerouslySetInnerHTML={{ __html: content_with_branded_headers }} />;
};

const BlogPost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/blog-posts`);
        if (!response.ok) throw new Error('Failed to fetch posts');

        const data = await response.json();
        const matchingPost = data.data.find(post => post.Slug === slug);

        if (!matchingPost) {
          throw new Error('Post not found');
        }

        const formattedPost = {
          id: matchingPost.id,
          Title: matchingPost.Title,
          Content: matchingPost.Content,
          Description: matchingPost.Description,
          Author: matchingPost.Author,
          PublishedDate: matchingPost.PublishedDate,
          Tags: matchingPost.Tags,
          CoverImage: matchingPost.CoverImage,
          readTime: `${Math.ceil((typeof matchingPost.Content === 'string' ?
            matchingPost.Content.length :
            JSON.stringify(matchingPost.Content).length) / 200)} min read`
        };

        setPost(formattedPost);
      } catch (err) {
        console.error('Error fetching blog post:', err);
        setError(err.message || 'Failed to fetch blog post');
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  const handleShare = (platform) => {
    const url = window.location.href;
    const shareText = `Interesting read from Treasure Tracker!\n\n${url}\n\nHighly recommend checking it out.`;

    const shareUrls = {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(shareText)}`,
      x: `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}`,
      linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}&summary=${encodeURIComponent(shareText)}`
    };

    window.open(shareUrls[platform], '_blank', 'width=600,height=400');
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-center">
          <div className="text-red-500 mb-4">{error}</div>
          <Link
            to="/blog"
            className="inline-flex items-center gap-2 text-blue-600 hover:text-blue-700 transition-colors"
          >
            <ArrowLeft className="h-4 w-4" />
            Back to Blog
          </Link>
        </div>
      </div>
    );
  }

  if (!post) return <div>Post not found</div>;

  return (
    <div className="flex flex-col min-h-screen">
      {post && (
        <SEOHead
          title={`${post.Title} | Treasure Tracker Blog`}
          description={post.Description}
          path={`/blog/${slug}`}
          imagePath={post.CoverImage?.url || "/images/tt-social-share.png"}
          type="article"
          articleAuthor={post.Author}
        />
      )}
      {/* Hero Section */}
      <section className="relative pt-32 pb-20">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-500/90 to-purple-500/90 backdrop-blur-sm" />
        <div
          className="absolute inset-0 opacity-10"
          style={{
            backgroundImage: `radial-gradient(circle at 1px 1px, rgba(255, 255, 255, 0.15) 1px, transparent 0)`,
            backgroundSize: '20px 20px'
          }}
        />

        <div className="relative container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <Link
              to="/blog"
              className="inline-flex items-center gap-2 px-4 py-2 rounded-full 
                        bg-white/10 backdrop-blur-sm text-white mb-8 
                        hover:bg-white/20 transition-colors"
            >
              <ArrowLeft className="h-4 w-4" />
              Back to Blog
            </Link>
            <h1 className="text-4xl md:text-5xl font-gilmer font-bold mb-6 
                          bg-gradient-to-r from-white via-white/90 to-white 
                          bg-clip-text text-transparent">
              {post.Title}
            </h1>
            <div className="flex items-center justify-center gap-6 text-white/80">
              <div className="flex items-center gap-2">
                <User className="h-4 w-4" />
                {post.Author}
              </div>
              <div className="flex items-center gap-2">
                <Clock className="h-4 w-4" />
                {post.readTime}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Content Section */}
      <section className="py-20 bg-gradient-to-b from-gray-50 to-white">
        <div className="container mx-auto px-4">
          <article className="max-w-4xl mx-auto">
            {/* Cover Image */}
            {post.CoverImage && (
              <div className="relative aspect-video mb-12 rounded-xl overflow-hidden shadow-2xl">
                <img
                  src={`${process.env.REACT_APP_STRAPI_URL}${post.CoverImage.url}`}
                  alt={post.Title}
                  className="object-cover w-full h-full"
                />
              </div>
            )}

            {/* Rich Text Content */}
            <div className="mb-16">
              <RichText content={post.Content} />
            </div>

            {/* Social Sharing */}
            <div className="mt-16 pt-8 border-t border-gray-200">
              <h3 className="text-xl font-gilmer font-bold text-gray-900 mb-4">Share this article</h3>
              <div className="flex flex-col sm:flex-row gap-3 sm:gap-4">
                <button
                  onClick={() => handleShare('facebook')}
                  className="flex items-center justify-center gap-2 px-4 sm:px-6 py-3 
                           bg-[#1877F2] text-white rounded-full text-sm sm:text-base
                           hover:bg-[#1664d9] transition-colors w-full sm:w-auto"
                >
                  <FaSquareFacebook className="text-xl" />
                  <span>Share</span>
                </button>
                <button
                  onClick={() => handleShare('x')}
                  className="flex items-center justify-center gap-2 px-4 sm:px-6 py-3 
                           bg-black text-white rounded-full text-sm sm:text-base
                           hover:bg-gray-800 transition-colors w-full sm:w-auto"
                >
                  <FaXTwitter className="text-xl" />
                  <span>Post</span>
                </button>
                <button
                  onClick={() => handleShare('linkedin')}
                  className="flex items-center justify-center gap-2 px-4 sm:px-6 py-3 
                           bg-[#0A66C2] text-white rounded-full text-sm sm:text-base
                           hover:bg-[#084d93] transition-colors w-full sm:w-auto"
                >
                  <FaLinkedin className="text-xl" />
                  <span>Share</span>
                </button>
              </div>
            </div>
          </article>
        </div>
      </section>
    </div>
  );
};

export default BlogPost;