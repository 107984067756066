import React from 'react';
import { Trash2, AlertCircle, Mail, ArrowRight } from 'lucide-react';
import { Card, CardContent } from '../components/ui/Card';
import SEOHead from '../components/SEOHead';

function DataDeletion() {
  return (
    <div className="flex flex-col min-h-screen">
      <SEOHead
        title="Data Deletion Instructions - Treasure Tracker"
        description="Learn how to remove your data from Treasure Tracker. Step-by-step instructions for deleting your account and associated information from our systems."
        path="/data-deletion"
        type="website"
      />
      {/* Hero Section */}
      <section className="relative pt-32 pb-20">
        {/* Background gradient */}
        <div className="absolute inset-0 bg-gradient-to-br from-blue-500/90 to-purple-500/90" />

        {/* Dot pattern overlay */}
        <div className="absolute inset-0 opacity-10"
          style={{
            backgroundImage: `radial-gradient(circle at 1px 1px, rgba(255, 255, 255, 0.15) 1px, transparent 0)`,
            backgroundSize: '20px 20px'
          }}
        />

        <div className="relative container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <div className="inline-flex items-center justify-center gap-2 px-4 py-2 rounded-full bg-white/10 backdrop-blur-sm text-white mb-6">
              <Trash2 className="w-4 h-4" />
              <span className="text-sm font-medium">Data Control</span>
            </div>
            <h1 className="text-4xl md:text-5xl font-gilmer font-bold mb-6 text-white">
              Data Deletion Instructions
            </h1>
            <p className="text-xl text-white/80 mb-8">
              Learn how to remove your data from our systems
            </p>
          </div>
        </div>
      </section>

      {/* Content Section */}
      <section className="py-20 bg-gradient-to-b from-gray-50 to-white relative">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <Card className="p-8 shadow-xl">
              <CardContent className="space-y-8">
                {/* Introduction */}
                <p className="text-lg text-gray-700 leading-relaxed">
                  At Treasure Tracker LLC, we value your privacy and are committed to protecting your personal information. While we do not store your personal data directly, we use certain information for login purposes and to analyze advertising metrics. In compliance with Facebook Platform rules and data protection regulations, we provide the following instructions for data deletion:
                </p>

                {/* Facebook Data Deletion */}
                <div className="space-y-4">
                  <h2 className="text-2xl font-gilmer font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                    Facebook-Related Data Deletion
                  </h2>
                  <p className="text-lg text-gray-700">
                    To delete your activities associated with Treasure Tracker LLC on Facebook, please follow these steps:
                  </p>
                  <ol className="list-decimal list-inside space-y-3 pl-4 text-lg text-gray-700">
                    <li>Go to your Facebook Account's Settings & Privacy</li>
                    <li>Click on "Settings"</li>
                    <li>Navigate to "Apps and Websites"</li>
                    <li>Locate and select the checkbox next to Treasure Tracker</li>
                    <li>Click the "Remove" button</li>
                  </ol>
                </div>

                {/* App Data Deletion */}
                <div className="space-y-4">
                  <h2 className="text-2xl font-gilmer font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                    Additional Data Removal
                  </h2>
                  <p className="text-lg text-gray-700">
                    For any data that may be stored within the Treasure Tracker app:
                  </p>
                  <ol className="list-decimal list-inside space-y-3 pl-4 text-lg text-gray-700">
                    <li>Log in to your Treasure Tracker account</li>
                    <li>Navigate to Settings</li>
                    <li>Select "Delete Account"</li>
                    <li>Confirm your decision to delete your account and associated data</li>
                  </ol>
                </div>

                {/* Warning Note */}
                <div className="bg-blue-50 border-l-4 border-blue-500 p-6 rounded-r-xl">
                  <div className="flex items-start gap-4">
                    <AlertCircle className="w-6 h-6 text-blue-500 mt-1 flex-shrink-0" />
                    <div>
                      <p className="font-gilmer font-semibold text-lg text-gray-900 mb-2">Please note:</p>
                      <p className="text-gray-700">
                        Deleting your account will permanently remove all your data from our systems. This action cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>

                {/* Contact Support */}
                <div className="space-y-4">
                  <p className="text-lg text-gray-700">
                    If you encounter any issues during the deletion process or need further assistance, please don't hesitate to contact our support team:
                  </p>
                  <a
                    href="mailto:jackson.white@mytreasuretracker.com"
                    className="inline-flex items-center gap-2 bg-blue-600 text-white px-6 py-3 rounded-full
                             transition-all duration-300 hover:bg-blue-500 hover:shadow-lg 
                             hover:shadow-blue-500/25 group"
                  >
                    <Mail className="w-5 h-5" />
                    <span>Contact Support</span>
                    <ArrowRight className="w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
                  </a>
                </div>

                {/* Last Updated */}
                <div className="pt-6 border-t border-gray-200">
                  <p className="text-sm text-gray-500">
                    Last updated: {new Date().toLocaleDateString()}
                  </p>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DataDeletion;