import { useEffect, useState, useRef } from 'react';

function useTermlyEmbed(policyId) {
    const [status, setStatus] = useState('loading');
    const [error, setError] = useState(null);
    const iframeRef = useRef(null);
    const attemptCount = useRef(0);

    const createDirectIframe = () => {
        if (!iframeRef.current) {
            const iframe = document.createElement('iframe');
            iframe.src = `https://app.termly.io/document/privacy-policy/${policyId}`;
            iframe.style.width = '100%';
            iframe.style.height = '100%';
            iframe.style.border = 'none';
            
            const container = document.querySelector('.termly-embed-container');
            if (container) {
                container.innerHTML = '';
                container.appendChild(iframe);
                iframeRef.current = iframe;
                setStatus('loaded');
            }
        }
    };

    const loadTermly = async () => {
        try {
            if (window.Termly && typeof window.Termly.load === 'function') {
                window.Termly.load(policyId);
                setStatus('loaded');
                return;
            }

            const existingScript = document.querySelector('script[src*="termly.io"]');
            if (existingScript) {
                existingScript.remove();
            }

            if (attemptCount.current === 0) {
                attemptCount.current += 1;
                
                const scriptElement = document.createElement('script');
                scriptElement.src = 'https://app.termly.io/embed-policy.min.js';
                scriptElement.async = true;
                
                const loadPromise = new Promise((resolve, reject) => {
                    scriptElement.onload = () => {
                        setTimeout(() => {
                            if (window.Termly && typeof window.Termly.load === 'function') {
                                resolve();
                            } else {
                                reject(new Error('blocked'));
                            }
                        }, 500);
                    };
                    
                    scriptElement.onerror = () => reject(new Error('blocked'));
                });

                document.body.appendChild(scriptElement);

                try {
                    await Promise.race([
                        loadPromise,
                        new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), 5000))
                    ]);
                    
                    window.Termly.load(policyId);
                    setStatus('loaded');
                } catch (err) {
                    setError({
                        type: 'adblock',
                        message: 'It looks like your ad blocker is preventing our policy from loading.'
                    });
                    createDirectIframe();
                }
            } else {
                createDirectIframe();
            }
        } catch (err) {
            setError({
                type: 'adblock',
                message: 'It looks like your ad blocker is preventing our policy from loading.'
            });
            createDirectIframe();
        }
    };

    useEffect(() => {
        loadTermly();

        return () => {
            const existingScript = document.querySelector('script[src*="termly.io"]');
            if (existingScript) {
                existingScript.remove();
            }
            if (iframeRef.current) {
                iframeRef.current.remove();
                iframeRef.current = null;
            }
        };
    }, [policyId]);

    return {
        isLoaded: status === 'loaded',
        error,
        reload: () => {
            setStatus('loading');
            setError(null);
            attemptCount.current = 0;
            if (iframeRef.current) {
                iframeRef.current.remove();
                iframeRef.current = null;
            }
            if (window.Termly && typeof window.Termly.load === 'function') {
                window.Termly.load(policyId);
                setStatus('loaded');
            } else {
                const container = document.querySelector('.termly-embed-container');
                if (container) {
                    container.innerHTML = '';
                }
                window.Termly = undefined;
                loadTermly();
            }
        }
    };
}

export default useTermlyEmbed;