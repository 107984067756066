import React from 'react';
import { Helmet } from "react-helmet-async";

const SEOHead = ({
    title = "Treasure Tracker - Preserve Your Family's Legacy",
    description = "Document, protect, and share your family's treasured possessions with Treasure Tracker. Easy-to-use app for preserving memories and connecting generations.",
    path = "",
    imagePath = "/images/tt-social-share.png",
    pageType = "website", // Changed from 'type' to 'pageType' to avoid confusion
    articlePublishDate,
    articleModifiedDate,
    articleAuthor
}) => {
    const baseUrl = "https://mytreasuretracker.com";
    const currentUrl = `${baseUrl}${path}`;
    const imageUrl = imagePath.startsWith('http') ? imagePath : `${baseUrl}${imagePath}`;

    const getStructuredData = () => {
        const baseData = {
            "@context": "https://schema.org",
            "@type": pageType === "article" ? "Article" : "WebPage",
            url: currentUrl,
            headline: title,
            description: description,
            image: imageUrl,
        };

        // Different schemas based on page type
        switch (pageType) {
            case "article":
                return {
                    ...baseData,
                    datePublished: articlePublishDate,
                    dateModified: articleModifiedDate || articlePublishDate,
                    author: {
                        "@type": "Person",
                        name: articleAuthor
                    },
                    publisher: {
                        "@type": "Organization",
                        name: "Treasure Tracker",
                        logo: {
                            "@type": "ImageObject",
                            url: `${baseUrl}/images/logo.png`
                        }
                    }
                };

            case "blog":
                return {
                    ...baseData,
                    "@type": "Blog",
                    publisher: {
                        "@type": "Organization",
                        name: "Treasure Tracker",
                        logo: {
                            "@type": "ImageObject",
                            url: `${baseUrl}/images/logo.png`
                        }
                    }
                };

            case "legal":
                return {
                    "@context": "https://schema.org",
                    "@type": "LegalDocument",
                    name: title,
                    description: description,
                    publisher: {
                        "@type": "Organization",
                        name: "Treasure Tracker"
                    },
                    datePublished: new Date().toISOString().split('T')[0],
                    url: currentUrl
                };

            default:
                return {
                    ...baseData,
                    "@type": "WebPage",
                    publisher: {
                        "@type": "Organization",
                        name: "Treasure Tracker"
                    }
                };
        }
    };

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />

            {pageType === "legal" && (
                <>
                    <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <meta name="revisit-after" content="7 days" />
                </>
            )}

            {/* Blog-specific meta tags */}
            {pageType === "blog" && (
                <>
                    <link rel="alternate" type="application/rss+xml" title="Treasure Tracker Blog RSS Feed" href={`${baseUrl}/feed.xml`} />
                    <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                </>
            )}

            {/* Open Graph / Facebook */}
            <meta property="og:type" content={pageType === "article" ? "article" : "website"} />
            <meta property="og:url" content={currentUrl} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={imageUrl} />

            {/* Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:url" content={currentUrl} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={imageUrl} />

            {/* Additional SEO tags */}
            <meta name="keywords" content="family heirlooms, treasure tracking, family history, legacy preservation, digital inheritance, family memories, asset management, family stories" />
            <link rel="canonical" href={currentUrl} />

            {/* Language */}
            <html lang="en" />

            {/* Structured Data */}
            <script type="application/ld+json">
                {JSON.stringify(getStructuredData())}
            </script>
        </Helmet>
    );
};

export default SEOHead;