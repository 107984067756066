import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Phone, Mail, Check, Download, Loader2, Send } from 'lucide-react';
import { Card } from '../components/ui/Card';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import SEOHead from '../components/SEOHead';

const DownloadPage = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [submitStatus, setSubmitStatus] = useState('idle');
    const [captchaToken, setCaptchaToken] = useState('');
    const [remainingAttempts, setRemainingAttempts] = useState(3);
    const [showCaptchaPrompt, setShowCaptchaPrompt] = useState(false);
    const captchaRef = React.useRef(null);

    const formatPhoneNumber = (value) => {
        const numbers = value.replace(/\D/g, '');
        if (numbers.length <= 3) return numbers;
        if (numbers.length <= 6) return `(${numbers.slice(0, 3)}) ${numbers.slice(3)}`;
        return `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}-${numbers.slice(6, 10)}`;
    };

    const handlePhoneNumberChange = (e) => {
        const formatted = formatPhoneNumber(e.target.value);
        setPhoneNumber(formatted);
    };

    const handleCaptchaVerify = (token) => {
        setCaptchaToken(token);
    };

    const getButtonStyles = (status) => {
        const baseStyles = "w-full px-6 py-4 rounded-xl font-medium text-lg transition-all duration-300 flex items-center justify-center gap-2 hover:shadow-lg";

        switch (status) {
            case 'loading':
                return `${baseStyles} bg-blue-600/50 text-white cursor-not-allowed`;
            case 'success':
                return `${baseStyles} bg-green-500 text-white hover:bg-green-400 hover:shadow-green-500/25`;
            case 'error':
                return `${baseStyles} bg-red-500 text-white hover:bg-red-400 hover:shadow-red-500/25`;
            default:
                return `${baseStyles} bg-blue-600 text-white hover:bg-blue-500 hover:shadow-blue-500/25`;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!captchaToken) {
            setShowCaptchaPrompt(true);
            setTimeout(() => setShowCaptchaPrompt(false), 5000);
            return;
        }

        // Check remaining attempts before proceeding
        if (remainingAttempts <= 0) {
            setSubmitStatus('error');
            setTimeout(() => setSubmitStatus('idle'), 3000);
            return;
        }

        setSubmitStatus('loading');

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/send-download-link`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    phoneNumber: phoneNumber.replace(/\D/g, ''),
                    captchaToken,
                    userAgent: window.navigator.userAgent
                })
            });

            const data = await response.json();

            if (!response.ok) {
                if (response.status === 429) {
                    // Rate limit reached
                    setRemainingAttempts(0);
                    throw new Error('Too many download link requests. Please try again later.');
                }
                throw new Error(data.error || 'Failed to send download link');
            }

            setSubmitStatus('success');
            // Only decrement attempts on successful submission
            setRemainingAttempts(prev => {
                const newValue = prev - 1;
                return newValue >= 0 ? newValue : 0;
            });

            setTimeout(() => {
                setSubmitStatus('idle');
                setPhoneNumber('');
                setCaptchaToken('');
                if (captchaRef.current) {
                    captchaRef.current.resetCaptcha();
                }
            }, 3000);

        } catch (error) {
            console.error('Submission error:', error);
            setSubmitStatus('error');

            if (error.message.includes('Too many download link requests')) {
                setRemainingAttempts(0);
            }

            setTimeout(() => {
                setSubmitStatus('idle');
                setCaptchaToken('');
                if (captchaRef.current) {
                    captchaRef.current.resetCaptcha();
                }
            }, 3000);
        }
    };

    return (
        <div className="flex flex-col min-h-screen">
            <SEOHead
                title="Download Treasure Tracker | Start Preserving Memories"
                description="Download Treasure Tracker now for iOS or Android. Get started documenting and sharing your family's treasured possessions today."
                path="/download"
            />
            {/* Hero Section */}
            <section className="relative pt-32 pb-20">
                <div className="absolute inset-0 bg-gradient-to-br from-blue-500/90 to-purple-500/90" />
                <div className="absolute inset-0 opacity-10"
                    style={{
                        backgroundImage: `radial-gradient(circle at 1px 1px, rgba(255, 255, 255, 0.15) 1px, transparent 0)`,
                        backgroundSize: '20px 20px'
                    }}
                />

                <div className="relative container mx-auto px-4">
                    <div className="max-w-3xl mx-auto text-center">
                        <div className="inline-flex items-center justify-center gap-2 px-4 py-2 rounded-full bg-white/10 backdrop-blur-sm text-white mb-6">
                            <Download className="w-4 h-4" />
                            <span className="text-sm font-medium">Get Started in Minutes</span>
                        </div>
                        <h1 className="text-4xl md:text-5xl font-gilmer font-bold mb-6 text-white">
                            Download Treasure Tracker
                        </h1>
                        <p className="text-xl text-white/80 mb-8">
                            Start preserving your family's legacy today with our easy-to-use mobile app
                        </p>
                    </div>
                </div>
            </section>

            {/* Download Section */}
            <section className="py-20 bg-gradient-to-b from-gray-50 to-white relative">
                <div className="container mx-auto px-4">
                    <div className="max-w-xl mx-auto">
                        {/* Text Link Form */}
                        <Card className="p-8 mb-12 bg-white shadow-lg hover:shadow-xl transition-shadow duration-300 rounded-2xl">
                            <div className="flex items-center gap-4 mb-8">
                                <div className="w-16 h-16 rounded-full bg-blue-100/50 flex items-center justify-center">
                                    <Phone className="w-8 h-8 text-blue-600" />
                                </div>
                                <div>
                                    <h3 className="text-2xl font-gilmer font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                                        Get Download Link
                                    </h3>
                                    <p className="text-gray-600">We'll send the right link for your device</p>
                                </div>
                            </div>

                            <form onSubmit={handleSubmit} className="space-y-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Phone Number {remainingAttempts < 3 && (
                                            <span className="text-gray-500 ml-2">
                                                ({remainingAttempts} attempts remaining)
                                            </span>
                                        )}
                                    </label>
                                    <input
                                        type="tel"
                                        value={phoneNumber}
                                        onChange={handlePhoneNumberChange}
                                        placeholder="(123) 456-7890"
                                        className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:ring-2 
                                        focus:ring-blue-500 focus:border-transparent text-lg transition-all"
                                        disabled={remainingAttempts === 0 || submitStatus === 'loading'}
                                    />
                                </div>

                                <div className="flex justify-center">
                                    <HCaptcha
                                        ref={captchaRef}
                                        sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
                                        onVerify={handleCaptchaVerify}
                                        onExpire={() => setCaptchaToken('')}
                                    />
                                </div>


                                {showCaptchaPrompt && (
                                    <div className="transition-all duration-300 ease-in-out">
                                        <p className="text-blue-600 text-sm text-center bg-blue-50 py-3 px-4 rounded-lg border border-blue-100 shadow-sm">
                                            Please complete the CAPTCHA verification above to continue
                                        </p>
                                    </div>
                                )}

                                <button
                                    type="submit"
                                    disabled={remainingAttempts <= 0 || submitStatus === 'loading'}
                                    onClick={() => {
                                        if (!captchaToken) {
                                            setShowCaptchaPrompt(true);
                                            setTimeout(() => setShowCaptchaPrompt(false), 5000);
                                        }
                                    }}
                                    className={getButtonStyles(submitStatus)}
                                >
                                    {submitStatus === 'loading' ? (
                                        <>
                                            <Loader2 className="h-5 w-5 animate-spin" />
                                            <span>Sending...</span>
                                        </>
                                    ) : submitStatus === 'success' ? (
                                        <>
                                            <Check className="w-5 h-5" />
                                            <span>Link Sent!</span>
                                        </>
                                    ) : submitStatus === 'error' ? (
                                        remainingAttempts <= 0 ?
                                            'Maximum attempts reached' :
                                            'Error sending link - Try again'
                                    ) : (
                                        <>
                                            Send Download Link
                                            <Send className="w-5 h-5" />
                                        </>
                                    )}
                                </button>
                            </form>
                        </Card>

                        {/* Direct Store Links */}
                        <div className="text-center space-y-6">
                            <p className="text-gray-600">Or download directly from your preferred store:</p>
                            <div className="flex flex-col sm:flex-row gap-4 justify-center">
                                <a
                                    href="https://apps.apple.com/us/app/treasure-tracker-app/id6470204759"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex items-center justify-center gap-3 bg-black text-white px-8 py-4 
                                    rounded-full transition-all duration-300 hover:scale-105 
                                    hover:shadow-lg group"
                                >
                                    <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                                        <path d="M17.05 20.28c-.98.95-2.05.86-3.08.38-1.07-.5-2.06-.48-3.2 0-1.42.68-2.17.58-3.08-.38C3.33 15.85 4.14 8.23 9.15 8c1.2.06 2.04.54 2.79.54.73 0 2.11-.66 3.56-.56 1.53.12 2.65.78 3.38 1.86-3.03 1.94-2.52 5.81.17 7.04-.62 1.76-1.42 3.54-2 3.4zM12.03 7.96c-.09-3.16 2.37-5.94 5.41-5.94.17 3.17-2.89 6.13-5.41 5.94z" />
                                    </svg>
                                    <span className="font-medium">Download on App Store</span>
                                </a>
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.treasure.tracker.production"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex items-center justify-center gap-3 bg-[#01875F] text-white px-8 py-4
                                    rounded-full transition-all duration-300 hover:scale-105
                                    hover:shadow-lg group"
                                >
                                    <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                                        <path d="M22.018 13.298l-3.919 2.218-3.515-3.493 3.543-3.521 3.891 2.202a1.49 1.49 0 0 1 0 2.594zM1.337.924a1.486 1.486 0 0 0-.112.568v21.017c0 .217.045.419.124.6l11.155-11.087L1.337.924zm12.207 10.065l3.258-3.238L3.45.195a1.466 1.466 0 0 0-.946-.179l11.04 10.973zm0 2.067l-11 10.933c.298.036.612-.016.906-.183l13.324-7.54-3.23-3.21z" />
                                    </svg>
                                    <span className="font-medium">Get it on Google Play</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Help Section */}
            <section className="py-20 bg-gradient-to-r from-blue-500 to-purple-500">
                <div className="container mx-auto px-4 text-center">
                    <h2 className="text-3xl font-bold text-white mb-6">
                        Need Help?
                    </h2>
                    <p className="text-xl text-white/80 mb-8 max-w-2xl mx-auto">
                        Our support team is here to assist you with the download process
                    </p>
                    <Link
                        to="/contact"
                        className="inline-flex items-center gap-2 bg-white text-blue-600 px-8 py-3 
                        rounded-full font-medium transition-all duration-200 
                        hover:bg-opacity-90 hover:scale-105"
                    >
                        Contact Support
                        <Mail className="w-5 h-5" />
                    </Link>
                </div>
            </section>
        </div>
    );
};

export default DownloadPage;