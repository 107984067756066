import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import Header from './components/Header';
import Footer from './components/Footer';
import LoadingScreen from './components/LoadingScreen';
import ScrollToTop from './components/ScrollToTop';

// Import pages
import HomePage from './pages/HomePage';
import ProductPage from './pages/ProductPage';
import AboutPage from './pages/AboutPage';
import CorporatePage from './pages/CorporatePage';
import PricingPage from './pages/PricingPage';
import BlogPage from './pages/BlogPage';
import BlogPost from './pages/BlogPost';
import ContactPage from './pages/ContactPage';
import DownloadPage from './pages/DownloadPage';
import PrivacyPolicy from './pages/PrivacyPolicyPage';
import TermsConditions from './pages/TermsConditionsPage';
import DataDeletion from './pages/DataDeletionPage';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    // Simulate initial loading
    const loadingTimer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    // Add a slight delay before showing content to ensure smooth transition
    const contentTimer = setTimeout(() => {
      setShowContent(true);
    }, 3500); // 500ms after loading completes

    return () => {
      clearTimeout(loadingTimer);
      clearTimeout(contentTimer);
    };
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <ScrollToTop /> {/* Add this component inside Router but outside other components */}
        <div className="flex flex-col min-h-screen">
          <LoadingScreen isLoading={isLoading} />
          {showContent && (
            <>
              <Header />
              <main className="flex-grow">
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/product" element={<ProductPage />} />
                  <Route path="/about" element={<AboutPage />} />
                  <Route path="/partnerships" element={<CorporatePage />} />
                  <Route path="/pricing" element={<PricingPage />} />
                  <Route path="/blog" element={<BlogPage />} />
                  <Route path="/blog/:slug" element={<BlogPost />} />
                  <Route path="/contact" element={<ContactPage />} />
                  <Route path="/download" element={<DownloadPage />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  <Route path="/terms" element={<TermsConditions />} />
                  <Route path="/data-deletion" element={<DataDeletion />} />
                </Routes>
              </main>
              <Footer />
            </>
          )}
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;