import React, { useState, useCallback, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const FeatureCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);
  const [hasFocus, setHasFocus] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const features = [
    {
      title: "Easy Digital Organization",
      description: "Quickly add items with photos, descriptions, and important details using our intuitive interface.",
      image: "/images/sort.png"
    },
    {
      title: "Story Preservation",
      description: "Add rich stories and memories to each item, preserving your family's history for generations.",
      image: "/images/story.png"
    },
    {
      title: "Secure Documentation",
      description: "Keep important documents, appraisals, and receipts organized and easily accessible.",
      image: "/images/documents.png"
    },
    {
      title: "Insurance Ready Reports",
      description: "Generate comprehensive reports for insurance claims and estate planning with one click.",
      image: "/images/reports.png"
    },
    {
      title: "Estate Planning",
      description: "Simplify inheritance planning by creating and updating your personal property memorandum digitally. Designate heirs and attach stories to each item.",
      image: "/images/assign.png"
    },
    {
      title: "Custom Categories",
      description: "Create personalized categories that match your collection. From family heirlooms to art pieces, organize your treasures in a way that makes sense to you.",
      image: "/images/category.png"
    },
    {
      title: "Multiple Properties",
      description: "Manage items across different locations with ease. Perfect for vacation homes, storage units, or helping family members organize their estates.",
      image: "/images/property.png"
    }
  ];

  const minSwipeDistance = 50;
  const totalFeatures = features.length;
  const autoPlayInterval = 3500; // Reduced to 3.5 seconds

  const calculateRotation = (index) => {
    const baseAngle = (360 / totalFeatures);
    let rotation = ((index - currentIndex) * baseAngle) % 360;
    if (rotation > 180) rotation -= 360;
    if (rotation < -180) rotation += 360;
    return rotation;
  };

  const calculateZIndex = (index) => {
    const diff = Math.abs(index - currentIndex);
    return totalFeatures - diff;
  };

  const handlePrevious = useCallback(() => {
    setCurrentIndex((prev) => (prev === 0 ? totalFeatures - 1 : prev - 1));
    setIsAutoPlaying(false);
  }, [totalFeatures]);

  const handleNext = useCallback(() => {
    setCurrentIndex((prev) => (prev === totalFeatures - 1 ? 0 : prev + 1));
    setIsAutoPlaying(false);
  }, [totalFeatures]);

  const handleCardTap = useCallback((e) => {
    // Prevent default behavior
    e.preventDefault();
    e.stopPropagation();
    
    // Simply advance to next slide
    handleNext();
    setIsAutoPlaying(false);
  }, [handleNext]);

  // Keyboard navigation
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (!hasFocus) return;
      if (e.key === 'ArrowLeft') {
        handlePrevious();
        e.preventDefault();
      }
      if (e.key === 'ArrowRight') {
        handleNext();
        e.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [handlePrevious, handleNext, hasFocus]);

  // Auto-play functionality
  useEffect(() => {
    if (!isAutoPlaying) return;
    const interval = setInterval(handleNext, autoPlayInterval);
    return () => clearInterval(interval);
  }, [isAutoPlaying, handleNext]);

  return (
    <div className="container mx-auto px-4 mt-48">
      <div className="relative h-[500px] w-full max-w-7xl mx-auto"
        role="region"
        aria-roledescription="carousel"
        aria-label="Features carousel"
        tabIndex="0"
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        onMouseEnter={() => setIsAutoPlaying(false)}
        onMouseLeave={() => setIsAutoPlaying(true)}>

        <div className="absolute inset-0 perspective-[1500px] mb-14">
          <div className="relative w-full h-full">
            {features.map((feature, index) => {
              const rotation = calculateRotation(index);
              const zIndex = calculateZIndex(index);
              const isActive = index === currentIndex;
              const isVisible = Math.abs(index - currentIndex) <= 2;

              return (
                <div
                  key={index}
                  className={`absolute left-1/2 top-1/2 w-[320px] 
                          transition-all duration-500 ease-out cursor-pointer
                          active:scale-95 hover:scale-105
                          ${isActive ? 'touch-none' : 'touch-pan-y'}`}
                  onClick={handleCardTap}
                  onTouchEnd={handleCardTap}
                  style={{
                    transform: `
                    translate(-50%, -50%)
                    rotateY(${rotation}deg)
                    translateZ(400px)
                    ${isActive ? 'scale(1.1)' : 'scale(0.9)'}
                  `,
                    zIndex,
                    opacity: isVisible ? 1 : 0.3,
                  }}
                >
                  <div
                    className={`rounded-2xl overflow-hidden shadow-lg ${isActive ? 'shadow-blue-500/30 ring-1 ring-blue-500/20' : ''
                      }`}
                    style={{
                      background: isActive
                        ? 'radial-gradient(circle at center, white 0%, rgb(239, 246, 255) 70%, rgb(219, 234, 254) 100%)'
                        : 'white'
                    }}
                  >
                    <div className="p-6 text-center flex flex-col items-center space-y-3">
                      <h3 className="text-lg font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent text-center w-full">
                        {feature.title}
                      </h3>
                      <p className="text-sm text-gray-600">
                        {feature.description}
                      </p>
                      <img
                        src={feature.image}
                        alt={feature.title}
                        className="w-[280px] h-[480px] mx-auto object-contain"
                        onError={(e) => {
                          e.target.src = `/api/placeholder/280/480`;
                        }}
                      />
                    </div>
                    {/* Subtle overlay for non-active cards */}
                    {!isActive && (
                      <div className="absolute inset-0 bg-white/60 backdrop-blur-[1px]" />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Navigation controls - increased bottom spacing */}
        <div className="absolute -bottom-40 left-1/2 -translate-x-1/2 flex items-center gap-8">
          <button
            onClick={handlePrevious}
            className="p-3 rounded-full text-blue-600 hover:bg-blue-50 transition-colors"
            aria-label="Previous feature"
          >
            <ChevronLeft className="w-8 h-8" />
          </button>

          <div className="flex gap-2">
            {features.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={`w-2 h-2 rounded-full transition-all duration-300 ${index === currentIndex
                  ? 'bg-blue-600 w-6'
                  : 'bg-gray-300 hover:bg-blue-400'
                  }`}
                aria-label={`Go to feature ${index + 1}`}
                aria-selected={index === currentIndex}
              />
            ))}
          </div>

          <button
            onClick={handleNext}
            className="p-3 rounded-full text-blue-600 hover:bg-blue-50 transition-colors"
            aria-label="Next feature"
          >
            <ChevronRight className="w-8 h-8" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeatureCarousel;