import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Check, ChevronRight, Sparkles } from 'lucide-react';
import SEOHead from '../components/SEOHead';

const PricingPage = () => {
  const [isAnnual, setIsAnnual] = useState(false);
  const [deviceType, setDeviceType] = useState('desktop');

  // Device detection logic remains the same
  const getMobileOperatingSystem = () => {
    const isDevMode = process.env.NODE_ENV === 'development';
    if (isDevMode) {
      const urlParams = new URLSearchParams(window.location.search);
      const deviceParam = urlParams.get('device');
      if (deviceParam) return deviceParam;
    }

    const userAgent = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(userAgent)) return 'ios';
    if (/android/.test(userAgent)) return 'android';
    return 'desktop';
  };

  useEffect(() => {
    setDeviceType(getMobileOperatingSystem());
  }, []);

  const getCtaButton = (planType) => {
    if (deviceType === 'desktop') {
      return (
        <Link
          to="/download"
          className="group w-full bg-primary-purple text-white px-6 py-4 rounded-full font-gilmer 
                   flex items-center justify-center gap-2 relative overflow-hidden
                   transition-all duration-300 hover:shadow-lg hover:shadow-primary-purple/30
                   before:absolute before:inset-0 before:bg-white before:opacity-0 before:transition-opacity
                   hover:before:opacity-10 active:scale-[0.98]"
        >
          <span className="relative z-10">Get Started</span>
          <ChevronRight className="w-5 h-5 relative z-10 transform group-hover:translate-x-1 transition-transform" />
          <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300
                        bg-gradient-to-r from-primary-purple via-primary-sky to-primary-purple bg-size-200 animate-gradient" />
        </Link>
      );
    }

    const appLink = deviceType === 'ios'
      ? "https://apps.apple.com/us/app/treasure-tracker-app/id6470204759"
      : "https://play.google.com/store/apps/details?id=com.treasure.tracker.production";

    return (
      <a
        href={appLink}
        target="_blank"
        rel="noopener noreferrer"
        className="group w-full bg-primary-purple text-white px-6 py-4 rounded-full font-gilmer 
                 flex items-center justify-center gap-2 relative overflow-hidden
                 transition-all duration-300 hover:shadow-lg hover:shadow-primary-purple/30
                 before:absolute before:inset-0 before:bg-white before:opacity-0 before:transition-opacity
                 hover:before:opacity-10 active:scale-[0.98]"
      >
        <span className="relative z-10">Download for {deviceType === 'ios' ? 'iOS' : 'Android'}</span>
        <ChevronRight className="w-5 h-5 relative z-10 transform group-hover:translate-x-1 transition-transform" />
        <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300
                      bg-gradient-to-r from-primary-purple via-primary-sky to-primary-purple bg-size-200 animate-gradient" />
      </a>
    );
  };

  const plans = [
    {
      name: "Basic Plan",
      description: "Perfect for personal use",
      price: "Free",
      features: [
        "Secure Storage for Up to 25 Treasured Items",
        "Document One Special Property",
        "Generate 3 Detailed Reports",
        "24/7 Data Protection"
      ]
    },
    {
      name: "Premium Annual Plan",
      description: "Save more on all features",
      price: isAnnual ? "$45" : "$3.75",
      period: isAnnual ? "/year" : "/month",
      originalPrice: isAnnual ? "$65/year" : "$6.50/month",
      popular: true,
      features: [
        "Unlimited Secure Item Storage",
        "Document All Your Properties",
        "Generate Unlimited Detailed Reports",
        "Access to Estate Planning Features",
        isAnnual ? "30% Savings Compared to Monthly Plan" : "Flexible Month-to-Month Commitment",
        "24/7 Data Protection"
      ]
    },
    {
      name: "Premium Monthly Plan",
      description: "Monthly flexibility with all features",
      price: isAnnual ? "$58.80" : "$4.90",
      period: isAnnual ? "/year" : "/month",
      originalPrice: isAnnual ? "$78/year" : "$6.50/month",
      features: [
        "Unlimited Secure Item Storage",
        "Document All Your Properties",
        "Generate Unlimited Detailed Reports",
        "Access to Estate Planning Features",
        "Flexible Month-to-Month Commitment",
        "24/7 Data Protection"
      ]
    }
  ];

  // Monthly/Annual Toggle with improved styling
  const BillingToggle = () => (
    <div className="flex items-center justify-center gap-6 bg-white/10 backdrop-blur-sm rounded-full p-2">
      <button
        onClick={() => setIsAnnual(false)}
        className={`px-6 py-2 rounded-full transition-all duration-300 ${!isAnnual
          ? 'bg-white text-primary-purple shadow-lg'
          : 'text-white/80 hover:text-white'
          }`}
      >
        Monthly
      </button>
      <button
        onClick={() => setIsAnnual(true)}
        className={`px-6 py-2 rounded-full transition-all duration-300 ${isAnnual
          ? 'bg-white text-primary-purple shadow-lg'
          : 'text-white/80 hover:text-white'
          }`}
      >
        Annual (Save 17%)
      </button>
    </div>
  );

  // Rest of the component remains the same, but update the hero section to use BillingToggle
  return (
    <div className="flex flex-col min-h-screen">
      <SEOHead
        title="Pricing Plans | Treasure Tracker App"
        description="Choose the perfect plan for preserving your family's legacy. From free basic features to premium options with unlimited storage and advanced features."
        path="/pricing"
      />
      {/* Hero Section */}
      <section className="pt-32 pb-20 bg-gradient-to-br from-blue-500/90 to-purple-500/90">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <div className="inline-flex items-center justify-center gap-2 px-4 py-2 rounded-full bg-white/10 backdrop-blur-sm text-white mb-6">
              <Sparkles className="w-4 h-4" />
              <span className="text-sm font-medium">Special Launch Pricing - Save up to 30%</span>
            </div>
            <h1 className="text-4xl md:text-5xl font-gilmer font-bold text-white mb-6">
              Choose Your Plan
            </h1>
            <p className="text-xl text-white/80 mb-8">
              Preserve Your Family's Legacy for Less Than a Cup of Coffee
            </p>
            <BillingToggle />
          </div>
        </div>
      </section>

      {/* Pricing Plans */}
      <section className="py-20 bg-gradient-to-b from-gray-50 to-white relative">
        {/* Dot pattern overlay */}
        <div
          className="absolute inset-0 opacity-[0.015]"
          style={{
            backgroundImage: `radial-gradient(circle at 1px 1px, rgb(59 130 246) 1px, transparent 0)`,
            backgroundSize: '40px 40px'
          }}
        />

        <div className="container mx-auto px-4">
          <div className="max-w-6xl mx-auto">
            <div className="grid md:grid-cols-3 gap-8">
              {plans.map((plan, index) => (
                <div
                  key={index}
                  className={`relative border-2 rounded-2xl p-8 transition-all duration-300 hover:shadow-xl
                ${plan.popular
                      ? 'border-primary-purple bg-white shadow-lg transform hover:-translate-y-1'
                      : 'border-primary-pink/20 hover:border-primary-purple'
                    }`}
                >
                  {plan.popular && (
                    <div className="absolute -top-5 left-1/2 -translate-x-1/2 bg-gradient-to-r from-primary-purple to-primary-sky 
                     text-white px-6 py-2 rounded-full text-sm font-gilmer shadow-lg
                     animate-pulse-subtle">
                      <span className="relative">
                        Most Popular
                        <span className="absolute inset-0 bg-primary-purple blur-sm opacity-0"></span>
                      </span>
                    </div>
                  )}

                  <h3 className="relative z-10 mb-4">
                    <span className="text-3xl font-gilmer font-bold bg-gradient-to-r from-blue-600 to-purple-600 
                   bg-clip-text text-transparent relative inline-block
                   transform transition-all duration-300 hover:scale-105">
                      {plan.name}
                    </span>
                  </h3>
                  <p className="text-gray-600 mb-6">{plan.description}</p>

                  {plan.price === "Free" ? (
                    <div className="mb-6">
                      <span className="text-4xl font-gilmer font-bold text-primary-navy">Free</span>
                    </div>
                  ) : (
                    <div className="mb-6">
                      <div className="flex items-center gap-2 mb-1">
                        <span className="text-4xl font-gilmer font-bold text-primary-navy">{plan.price}</span>
                        <span className="text-gray-600">{plan.period}</span>
                      </div>
                      <div className="text-sm text-gray-500">
                        <span className="line-through">{plan.originalPrice}</span>
                        <span className="ml-2 text-primary-purple font-medium">Launch Special</span>
                      </div>
                    </div>
                  )}

                  <ul className="space-y-4 mb-8">
                    {plan.features.map((feature, index) => (
                      <li key={index} className="flex items-center gap-2">
                        <Check className="h-5 w-5 text-primary-purple" />
                        <span className="text-gray-600">{feature}</span>
                      </li>
                    ))}
                  </ul>

                  {getCtaButton(plan.name)}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-20 bg-primary-navy/5">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center mb-16">
            <h2 className="text-4xl font-gilmer font-bold mb-6 bg-gradient-to-r from-blue-700 via-purple-600 to-blue-700 bg-clip-text text-transparent">
              Frequently Asked Questions
            </h2>
            <p className="text-xl text-gray-600">
              Everything you need to know about our plans and pricing
            </p>
          </div>

          <div className="max-w-3xl mx-auto grid gap-8">
            {[
              {
                question: "Can I switch plans later?",
                answer: "Yes, you can upgrade or downgrade your plan at any time. Changes will be reflected in your next billing cycle."
              },
              {
                question: "Is there a free trial?",
                answer: "Yes, we have a Basic Plan for new users that are interested in trying the app with no committment."
              },
              {
                question: "How secure is my data?",
                answer: "We use industry-leading encryption and security measures to protect your data. Your information is stored in secure, encrypted servers."
              },
              {
                question: "What happens if I cancel?",
                answer: "You can export all your data at any time. If you cancel, you'll have access until the end of your billing period."
              }
            ].map((faq, index) => (
              <div key={index} className="bg-white p-6 rounded-2xl">
                <h3 className="text-xl font-gilmer font-bold text-primary-navy mb-2">
                  {faq.question}
                </h3>
                <p className="text-gray-600">{faq.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-r from-primary-purple to-primary-sky">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-gilmer font-bold text-white mb-6">
            Ready to Get Started?
          </h2>
          <p className="text-xl text-white/80 mb-8 max-w-2xl mx-auto">
            Join thousands of families who are already preserving their treasured memories with Treasure Tracker.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            {deviceType === 'desktop' ? (
              <>
                <Link
                  to="/download"
                  className="bg-white text-primary-purple hover:bg-white/90 px-8 py-3 rounded-full font-gilmer"
                >
                  Start Free Trial
                </Link>
                <Link
                  to="/contact"
                  className="bg-white/10 text-white hover:bg-white/20 px-8 py-3 rounded-full font-gilmer"
                >
                  Contact Sales
                </Link>
              </>
            ) : (
              <>
                <a
                  href={deviceType === 'ios'
                    ? "https://apps.apple.com/us/app/treasure-tracker-app/id6470204759"
                    : "https://play.google.com/store/apps/details?id=com.treasure.tracker.production"
                  }
                  className="bg-white text-primary-purple hover:bg-white/90 px-8 py-3 rounded-full font-gilmer"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download for {deviceType === 'ios' ? 'iOS' : 'Android'}
                </a>
                <Link
                  to="/contact"
                  className="bg-white/10 text-white hover:bg-white/20 px-8 py-3 rounded-full font-gilmer"
                >
                  Contact Sales
                </Link>
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default PricingPage;