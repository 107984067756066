import React, { useRef, useEffect } from 'react';

const PressLogoBanner = () => {
  const scrollRef = useRef(null);
  
  const pressFeatures = [
    {
      name: "Yahoo Finance",
      logo: "/images/yahoo-finance-logo.png",
      url: "https://finance.yahoo.com/news/rising-entrepreneur-jackson-white-launches-141000896.html?guccounter=1"
    },
    {
      name: "Benzinga",
      logo: "/images/benzinga-logo.png",
      url: "https://www.benzinga.com/pressreleases/24/08/g40464312/rising-entrepreneur-jackson-white-launches-treasure-tracker-a-groundbreaking-app-preserving-family"
    },
    {
      name: "Economic Insider",
      logo: "/images/economic-insider-logo.png",
      url: "https://economicinsider.com/jackson-white-a-visionarys-journey-in-preserving-family-heritage/"
    },
    {
      name: "Valiant CEO",
      logo: "/images/valiant-ceo-logo.png",
      url: "https://valiantceo.com/jackson-white/"
    },
    {
      name: "CEO Weekly",
      logo: "/images/ceo-weekly-logo.png",
      url: "https://ceoweekly.com/treasure-tracker-preserving-family-legacies-digitally/"
    },
    {
      name: "Business Insider",
      logo: "/images/business-insider-logo.png",
      url: "https://markets.businessinsider.com/news/stocks/rising-entrepreneur-jackson-white-launches-treasure-tracker-a-groundbreaking-app-preserving-family-legacies-1033707864"
    },
    {
        name: "Authority Magaizine",
        logo: "/images/authority-magazine-logo.png",
        url: "https://medium.com/authority-magazine/startup-revolution-jackson-white-of-treasure-tracker-on-how-their-emerging-startup-is-changing-1eda8ddc12c9"
    }
  ];

  const doubledPress = [...pressFeatures, ...pressFeatures];

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    let scrollInterval;

    const startScroll = () => {
      scrollInterval = setInterval(() => {
        if (scrollContainer) {
          if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth / 2) {
            scrollContainer.scrollLeft = 0;
          } else {
            scrollContainer.scrollLeft += 1;
          }
        }
      }, 30);
    };

    startScroll();

    return () => {
      if (scrollInterval) {
        clearInterval(scrollInterval);
      }
    };
  }, []);

  return (
    <section className="py-8 bg-gradient-to-b from-blue-500/90 to-white/90">
      <div className="container mx-auto px-4">
        {/* Section Header - Reduced vertical spacing */}
        <div className="text-center mb-6">
          <h2 className="text-2xl font-bold bg-gradient-to-r from-blue-700 via-blue-500 to-purple-700 
                         bg-clip-text text-transparent mb-2">
            Featured In
          </h2>
          <div className="h-0.5 w-16 bg-gradient-to-r from-blue-500 to-purple-500 mx-auto rounded-full"/>
        </div>

        {/* Scrolling Banner - Reduced height */}
        <div className="relative overflow-hidden">
          {/* Gradient Overlays */}
          <div className="absolute left-0 top-0 bottom-0 w-32 z-10 bg-gradient-to-r from-white/90 to-transparent"/>
          <div className="absolute right-0 top-0 bottom-0 w-32 z-10 bg-gradient-to-l from-white/90 to-transparent"/>

          {/* Scrolling Content - Adjusted spacing */}
          <div 
            ref={scrollRef}
            className="flex gap-8 overflow-x-hidden whitespace-nowrap py-4"
          >
            {doubledPress.map((press, index) => (
              <a
                key={`${press.name}-${index}`}
                href={press.url}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center justify-center min-w-[180px] h-12 bg-white rounded-lg shadow-md px-6
                         transform transition-transform duration-300 hover:scale-105
                         hover:shadow-lg"
              >
                <div className="w-full h-8 relative">
                  <img
                    src={press.logo}
                    alt={`${press.name} logo`}
                    className="absolute inset-0 w-full h-full object-contain object-center"
                    onError={(e) => {
                      e.target.src = `/api/placeholder/180/48`;
                    }}
                  />
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PressLogoBanner;